import React from "react";
import "./DeptCaneDevelopment.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";

const DeptCaneDevelopment = () => {
  const { t } = useTranslation();

  const { banner, newsRoom, developmentSetion, container1, container2, container3  } = t("deptCaneDevelopment");

  var CaneImgs = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    cssEase: "linear",
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

  }

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="deptCane-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-5 d-flex justify-content-center align-items-center">
              <div className="col-md-5">
                <h2 className="investor-title mb-5">{newsRoom.heading}</h2>
                <p className="department-sbtxt ">
                 {newsRoom.para}
                </p>
              </div>
              <div className="col-md-7">
                <div className="agriculture-multimg">
                  <Slider {...CaneImgs}>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="canedeve"
                        src={getAssetPath("assets/imgs/department/canedeve/cane1.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="canedeve"
                        src={getAssetPath("assets/imgs/department/canedeve/cane2.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="canedeve"
                        src={getAssetPath("assets/imgs/department/canedeve/cane3.jpg")}
                      ></img>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="simple-idea abt-pdtop  cane-deve-bg">
        <div className="row mrg-0">
          <div className="container col-md-6">
            <h2 className="idea-title">{developmentSetion.heading}</h2>
          </div>
          <div className="container col-md-10">
            <p className="idea-para" style={{marginBottom : '100px'}}>
              {developmentSetion.para1}
              {developmentSetion.para2}
            </p>
          </div>
        </div>
      </section>
      <section className="candeve-content-bg">
      <div className="container">
          <div className="row mt-5">
            <div className="col-md-12">
                <h4 className="cane-development-title font-bold">
                  {container1.title}
                </h4>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath("assets/imgs/canedeve-img/candeve1.jpg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card1.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card1.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath(
                      "assets/imgs/canedeve-img/candeve2.jpg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card2.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card2.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath("assets/imgs/canedeve-img/candeve3.jpg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card3.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card3.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath("assets/imgs/canedeve-img/candeve4.jpg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card4.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card4.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath(
                      "assets/imgs/canedeve-img/candeve5.jpg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card5.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card5.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath(
                      "assets/imgs/canedeve-img/candeve6.jpg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card6.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card6.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath("assets/imgs/canedeve-img/candeve7.jpg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card7.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card7.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath("assets/imgs/canedeve-img/candeve8.jpg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card8.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card8.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath("assets/imgs/canedeve-img/candeve15.jpg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container1.cards.card9.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container1.cards.card9.para}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Facilities provided for increasing sugarcane production */}
          <div className="row mt-5">
            <div className="col-md-12">
                <h4 className="cane-development-title font-bold">
                  {container2.title}
                </h4>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath(
                      "assets/imgs/canedeve-img/candeve9.jpg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container2.cards.card1.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container2.cards.card1.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath(
                      "assets/imgs/canedeve-img/candeve10.jpg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container2.cards.card2.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container2.cards.card2.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath(
                      "assets/imgs/canedeve-img/candeve11.jpg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container2.cards.card3.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container2.cards.card3.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath("assets/imgs/canedeve-img/candeve12.jpg")}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container2.cards.card4.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container2.cards.card4.para}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Farmer Training and Honor Award  */}
          <div className="row mt-5">
            <div className="col-md-12">
                <h4 className="cane-development-title font-bold">
                  {container3.title}
                </h4>
            </div>
            <div className="col-md-6">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath(
                      "assets/imgs/canedeve-img/candeve13.jpg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container3.cards.card1.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container3.cards.card1.para}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="deve-facility">
                <div className="cane-facility-img">
                  <img
                    className="img-fluid"
                    alt="deve-facility"
                    src={getAssetPath(
                      "assets/imgs/canedeve-img/candeve14.jpg"
                    )}
                  ></img>
                </div>
                <div className="facility-info ">
                  <h4 className="facility-name font-bold">
                    {container3.cards.card2.heading}
                  </h4>
                  <p className="facility-desc font-regular">
                    {container3.cards.card2.para}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DeptCaneDevelopment;


