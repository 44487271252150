import React, { useEffect, useState } from "react";
import "./TenderApply.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  useGetLoggedUserQuery,
  useGetSingleTenderDetailQuery,
  useUploadSupplierAttachmentMutation,
  useGetPagesAccessQuery,
} from "../../services/userAuthApi";
import { getLocalStorageData } from "../../services/localStorageService";
import { downloadClick } from "../../services/commonFunction";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const TenderApply = () => {
  const { t } = useTranslation();

  const { section1, section2 } = t("tenderApply");

  const navigate = useNavigate();
  if (!getLocalStorageData("token") && getLocalStorageData("roleId") !== 2) {
    navigate("/TenderLogin");
  }
  const [showSingleTenderError, setSingleTenderError] = useState("");
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const { tenderId } = useParams();
  const [tenderData, setTenderData] = useState(["No Record"]);
  const [tenderList, setTenderList] = useState(["No Tender List"]);
  const token = getLocalStorageData("token");
  //start access page based on Role id
  const [hasPageAccess, setPageAccess] = useState(false);
  const { data: pageAccess, isSuccess: getAccessSuccess } =
    useGetPagesAccessQuery({ token });
  useEffect(() => {
    if (getAccessSuccess) {
      const pathname = window.location.pathname;
      const pageExists = pageAccess.some((page) => pathname.includes(page));
      setPageAccess(pageExists);
    }
  }, [pageAccess, getAccessSuccess]);
  //end access page based on Role id

  const {
    data: singleTender,
    isSuccess: singleTenderSuccess,
    isLoading,
    isError,
    error: singleTenderError,
  } = useGetSingleTenderDetailQuery({ token, tenderId }); // single tender detail
  const { data: otherTenders, isSuccess: showTendersSuccess } =
    useGetLoggedUserQuery({ token }); //show other tenders list

  const handleDownload = (tender) => {
    const url = tender.attachment[0].attachment;
    let fileName = url.substring(url.lastIndexOf("/") + 1);
    downloadClick(url, fileName);
  };

  // start apply tender
  const [file, setFile] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [applyTender] = useUploadSupplierAttachmentMutation(); // apply tender
  const handleRemarksChange = (e) => {
    setRemarks(e.target.value);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    document.getElementById("file-preview").innerText = selectedFile.name;
  };

  const handleButtonClick = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("attachment", file);
    formData.append("tenderId", tenderId);
    formData.append("remarks", remarks);
    formData.append("lang",  i18next.language);
    const res = await applyTender({ token, formData });
    if (res.error) {
      setError({ status: true, msg: res.error.data.message, type: "error" });
    }
    if (res.data.message === "success") {
      navigate("/Tenders/TenderSuccess");
    }
  };
  // end apply tender

  const handleCancelButtonClick = () => {
    navigate("/Tenders");
  };
  useEffect(() => {
    if (singleTender && singleTenderSuccess) {
      const response = singleTender.data;
      setTenderData(response);
    }
    if (isError) {
      setSingleTenderError(singleTenderError);
    }
  }, [singleTender, singleTenderSuccess, isError, singleTenderError]);

  useEffect(() => {
    if (otherTenders && showTendersSuccess) {
      const response = otherTenders.data;
      setTenderList(response);
    }
  }, [otherTenders, showTendersSuccess]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      {hasPageAccess ? (
        showSingleTenderError === "" ? (
          <section className="tenderapply-bg">
            <div className="Tenderapply-table">
              <div className="row ">
                <div className="col-md-4">
                  <div className="tenderlist-bg">
                    <p className="othertenders font-bold">{section1.title}</p>
                    <div className="othertender-listing">
                      {tenderList.map((tenders, index) => (
                        <div
                          className={`list-block tender_${index + 1}`}
                          key={index + 1}
                        >
                          <div className="date-block">
                            <p className="date-mnth font-bold">
                              {tenders.start_date}
                            </p>
                          </div>
                          <div className="tender-namedesc">
                            <p className="tendername font-semibold">
                              {tenders.title}
                            </p>
                            <p className="tenderdesc font-regular">
                              {tenders.description}
                            </p>
                            <Link
                              className={`btn btn-default btn-round apply-now ${
                                tenders.bidding_exists !== null
                                  ? "disabled"
                                  : ""
                              }`}
                              to={`/Tenders/TenderApply/${tenders.id}`}
                            >
                              {" "}
                              {tenders.bidding_exists === 0
                                ? `${section1.buttons.applied}`
                                : tenders.bidding_exists === 1
                                ? `${section1.buttons.accepted}`
                                : tenders.bidding_exists === 2
                                ? `${section1.buttons.rejected}`
                                : `${section1.buttons.apply}`}
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-8 mbl-row">
                  <div className="applyform-bg">
                    <div className="applynow-title">
                      <button
                        className="btn btn-default btn-round tender-Register"
                        onClick={handleCancelButtonClick}
                      >
                        <img
                          className="back"
                          alt="back"
                          src={getAssetPath("assets/icons/back-arrow.png")}
                        ></img>
                      </button>
                      <p className="apply-title font-bold">
                        {section2.heading}
                      </p>
                    </div>
                    <div className="main-form">
                      <div className="date">
                        <p className="date-title font-semibold">
                          {section2.date}
                        </p>
                        <p className="main-date font-regular">
                          {tenderData.start_date}
                        </p>
                      </div>
                      <div className="date">
                        <p className="date-title font-semibold">
                          {section2.title}
                        </p>
                        <p className="main-date font-regular">
                          {tenderData.title}
                        </p>
                      </div>
                      <div className="date">
                        <p className="date-title font-semibold">
                          {section2.description}
                        </p>
                        <p className="main-date font-regular">
                          {tenderData.description}
                        </p>
                      </div>
                      <div className="date">
                        <p className="date-title font-semibold">
                          {section2.dueDate}
                        </p>
                        <p className="main-date font-regular">
                          {tenderData.end_date}
                        </p>
                      </div>
                      <div className="document">
                        <p className="date-title font-semibold">
                        {section2.text1}
                        </p>
                        <button
                          className="btn btn-default btn-round download-doc"
                          onClick={() => handleDownload(tenderData)}
                        >
                         {section2.btn1}
                        </button>
                      </div>
                      <div
                        className="upload-docs"
                        onChange={handleFileChange}
                        onClick={() =>
                          document.querySelector(".upload-file").click()
                        }
                      >
                        <input
                          type="file"
                          accept="application/pdf,application/docx"
                          className="upload-file"
                          name="upload-file"
                          id="upload-file"
                          hidden
                        />
                        <img
                          className="file"
                          alt="upload-file"
                          src={getAssetPath("assets/icons/upload-file.png")}
                        ></img>
                        <p className="font-regular mb-0">{section2.text2}</p>
                      </div>
                      <div className="uploaded-row">
                        <div className="uploadeddoc-name">
                          <img
                            className="doc-icon"
                            alt="doc-icon"
                            src={getAssetPath("assets/icons/doc.png")}
                          ></img>
                          <span
                            className="uploadedDoc-title font-regular"
                            id="file-preview"
                          ></span>
                        </div>
                      </div>
                      <div className="txtarea-grp mt-5">
                        <textarea
                          placeholder={section2.textarea}
                          className="form-control rounded-0 font-regular remarks"
                          id="remarks"
                          name="remarks"
                          rows="1"
                          onChange={handleRemarksChange}
                        ></textarea>
                      </div>

                      <div className="cancel-submit">
                        <button
                          className="btn btn-default btn-round tender-cancel"
                          onClick={handleCancelButtonClick}
                        >
                          {section2.btn2}
                        </button>
                        <button
                          className="btn btn-default btn-round tender-submit"
                          onClick={handleButtonClick}
                        >
                           {section2.btn3}
                        </button>
                        {error.status ? (
                          <div style={{ color: "red" }}>{error.msg}</div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          singleTenderError.data.message
        )
      ) : (
        "Sorry!! does not have permission to access this page"
      )}
    </>
  );
};

export default TenderApply;
