import React from "react";
import "./Administration.css";
import { getAssetPath } from "../../../services/helpers";

import { useTranslation } from "react-i18next";

const Administration = () => {
  const { t } = useTranslation();

  const { banner, newsRoom, tenderMain_Table } = t("Administration");

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="administration-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-4 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-4">{newsRoom.heading}</h2>
                <p className="department-sbtxt ">{newsRoom.para}</p>
                <br/>
                <p className="department-sbtxt ">{newsRoom.para1}</p>
              </div>
              <div className="col-md-6 img-flex">
                <img
                  className="department-img"
                  alt="administration"
                  src={getAssetPath("assets/imgs/department/admin-img.png")}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tender-table">
        <div className="container">
          <div className="tendermain-table">
            <div className="col-md-12">
              <div className="login-search">
                <div className="farmer-name">
                  <div className="username investor-title">
                    <h2 className="font-bold mb-0" style={{fontWeight : '600'}}>{tenderMain_Table.heading}</h2>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-striped table-hover ">
                  <thead className="thead-dark th-height">
                    <tr>
                      <th scope="col">
                        {tenderMain_Table.table.tableHead.text1}
                      </th>
                      <th scope="col">
                        {tenderMain_Table.table.tableHead.text2}
                      </th>
                      <th scope="col">
                        {tenderMain_Table.table.tableHead.text3}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row1.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row1.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row1.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row2.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row2.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row2.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row3.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row3.text1}</td>
                      <td>
                        {tenderMain_Table.table.tableBody.row3.text2} <br />
                        {tenderMain_Table.table.tableBody.row3.text3}
                      </td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row4.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row4.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row4.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row5.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row5.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row5.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row6.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row6.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row6.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row7.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row7.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row7.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row8.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row8.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row8.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row9.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row9.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row9.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row10.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row10.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row10.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row11.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row11.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row11.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row12.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row12.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row12.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row13.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row13.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row13.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row14.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row14.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row14.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row15.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row15.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row15.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row16.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row16.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row16.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row17.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row17.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row17.text2}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.table.tableBody.row18.srNo}</td>
                      <td>{tenderMain_Table.table.tableBody.row18.text1}</td>
                      <td>{tenderMain_Table.table.tableBody.row18.text2}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Administration;
