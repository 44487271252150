import React from "react";
import "./Awards.css";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";

const Awards = () => {
  const { t } = useTranslation();

  const {
    banner,
    container,
    buttons,
    section1,
    section2,
    section3,
  } = t("awards");

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="awards-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="simple-idea abt-pdtop">
        <div className="container">
          <div className="row ">
            <div className="container col-md-5">
              <h2 className="idea-title">{container.heading}</h2>
            </div>
            <div className="container col-md-10">
              <p className="idea-para abt-para-mrg">{container.para}</p>
            </div>
          </div>
        </div>
      </section>

      <section className="awards-tabs">
        <div className="container mg-tp">
          <ul
            className="nav nav-pills justify-content-center"
            id="pills-tab"
            role="tablist"
          >
            <div className="awards-bg-pill">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active custom-tab"
                  id="Na-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Na"
                  type="button"
                  role="tab"
                  aria-controls="Na"
                  aria-selected="true"
                >
                  {buttons.btn1}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-tab"
                  id="Sa-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Sa"
                  type="button"
                  role="tab"
                  aria-controls="Sa"
                  aria-selected="false"
                >
                  {buttons.btn2}
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link custom-tab"
                  id="Sfa-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#Sfa"
                  type="button"
                  role="tab"
                  aria-controls="Sfa"
                  aria-selected="false"
                >
                  {buttons.btn3}
                </button>
              </li>
            </div>
          </ul>
        </div>
        <div className="tab-content custom-tb-cnt" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="Na"
            role="tabpanel"
            aria-labelledby="Na-tab"
          >
            <section className="tab-awards-bg">
              <div className="container">
                <h4 className="awards-tab-title font-bold investor-title">{section1.title}</h4>

                {/* desktop-view */}
                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath("assets/imgs/awards/award1.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath("assets/imgs/awards/award2.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award3.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award4.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul className="awards-list">
                  <li>{section1.awardsList.list1}</li>
                  <li>{section1.awardsList.list2}</li>
                  <li>{section1.awardsList.list3}</li>
                  <li>{section1.awardsList.list4}</li>
                  <li>{section1.awardsList.list5}</li>
                  <li>{section1.awardsList.list6}</li>
                  <li>{section1.awardsList.list7}</li>
                  <li>{section1.awardsList.list8}</li>
                  <li>{section1.awardsList.list9}</li>
                  <li>{section1.awardsList.list10}</li>
                  <li>{section1.awardsList.list11}</li>
                </ul> */}

                {/* timeline awardlist */}
                <div className="timeline">
                  <ul className="vertical-timeline list-unstyled">
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear1}</div>
                      <h5 className="award-name">{section1.awardsList.list1}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear2}</div>
                      <h5 className="award-name">{section1.awardsList.list2}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear3}</div>
                      <h5 className="award-name">{section1.awardsList.list3}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear4}</div>
                      <h5 className="award-name">{section1.awardsList.list4}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear5}</div>
                      <h5 className="award-name">{section1.awardsList.list5}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear6}</div>
                      <h5 className="award-name">{section1.awardsList.list6}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear7}</div>
                      <h5 className="award-name">{section1.awardsList.list7}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear8}</div>
                      <h5 className="award-name">{section1.awardsList.list8}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear9}</div>
                      <h5 className="award-name">{section1.awardsList.list9}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear10}</div>
                      <h5 className="award-name">{section1.awardsList.list10}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section1.awardsList.awardyear11}</div>
                      <h5 className="award-name">{section1.awardsList.list11}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
          <div
            className="tab-pane fade show"
            id="Sa"
            role="tabpanel"
            aria-labelledby="Sa-tab"
          >
            <section className="tab-awards-bg">
              <div className="container">
                <h4 className="awards-tab-title font-bold investor-title">{section2.title}</h4>

                {/* desktop-view */}
                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath("assets/imgs/awards/award5.png")}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award6.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award7.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award8.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul className="awards-list">
                  <li>{section2.awardsList.list1}</li>
                  <li>{section2.awardsList.list2}</li>
                  <li>{section2.awardsList.list3}</li>
                  <li>{section2.awardsList.list4}</li>
                  <li>{section2.awardsList.list6}</li>
                  <li>{section2.awardsList.list7}</li>
                  <li>{section2.awardsList.list8}</li>
                  <li>{section2.awardsList.list9}</li>
                  <li>{section2.awardsList.list10}</li>
                  <li>{section2.awardsList.list11}</li>
                  <li>{section2.awardsList.list12}</li>
                  <li>{section2.awardsList.list13}</li>
                </ul> */}

                {/* timeline awardlist */}
                <div className="timeline">
                  <ul className="vertical-timeline list-unstyled">
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear1}</div>
                      <h5 className="award-name">{section2.awardsList.list1}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear2}</div>
                      <h5 className="award-name">{section2.awardsList.list2}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear3}</div>
                      <h5 className="award-name">{section2.awardsList.list3}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear4}</div>
                      <h5 className="award-name">{section2.awardsList.list4}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear6}</div>
                      <h5 className="award-name">{section2.awardsList.list6}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear7}</div>
                      <h5 className="award-name">{section2.awardsList.list7}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear8}</div>
                      <h5 className="award-name">{section2.awardsList.list8}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear9}</div>
                      <h5 className="award-name">{section2.awardsList.list9}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear10}</div>
                      <h5 className="award-name">{section2.awardsList.list10}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear11}</div>
                      <h5 className="award-name">{section2.awardsList.list11}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear12}</div>
                      <h5 className="award-name">{section2.awardsList.list12}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section2.awardsList.awardyear13}</div>
                      <h5 className="award-name">{section2.awardsList.list13}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
          <div
            className="tab-pane fade show"
            id="Sfa"
            role="tabpanel"
            aria-labelledby="Sfa-tab"
          >
            <section className="tab-awards-bg">
              <div className="container">
                <h4 className="awards-tab-title font-bold investor-title">{section3.title}</h4>
                {/* desktop-view */}
                <div className="row mt-5">
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award9.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award10.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award11.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="awards-list">
                      <div className="awards-img">
                        <img
                          className="img-fluid"
                          alt="awards"
                          src={getAssetPath(
                            "assets/imgs/awards/award12.png"
                          )}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <ul className="awards-list">
                  <li>{section3.awardsList.link1}</li>
                  <li>{section3.awardsList.link2}</li>
                  <li>{section3.awardsList.link3}</li>
                  <li>{section3.awardsList.link4}</li>
                  <li>{section3.awardsList.link5}</li>
                  <li>{section3.awardsList.link6}</li>
                  <li>{section3.awardsList.link7}</li>
                  <li>{section3.awardsList.link8}</li>
                  <li>{section3.awardsList.link9}</li>
                  <li>{section3.awardsList.link10}</li>
                </ul> */}

                {/* timeline awardlist */}
                <div className="timeline">
                  <ul className="vertical-timeline list-unstyled">
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear1}</div>
                      <h5 className="award-name">{section3.awardsList.link1}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear2}</div>
                      <h5 className="award-name">{section3.awardsList.link2}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear3}</div>
                      <h5 className="award-name">{section3.awardsList.link3}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear4}</div>
                      <h5 className="award-name">{section3.awardsList.link4}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear5}</div>
                      <h5 className="award-name">{section3.awardsList.link5}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear6}</div>
                      <h5 className="award-name">{section3.awardsList.link6}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear7}</div>
                      <h5 className="award-name">{section3.awardsList.link7}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear8}</div>
                      <h5 className="award-name">{section3.awardsList.link8}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear9}</div>
                      <h5 className="award-name">{section3.awardsList.link9}</h5>
                    </li>
                    <li className="award-list">
                      <div className="award-year">{section3.awardsList.awardyear10}</div>
                      <h5 className="award-name">{section3.awardsList.link10}</h5>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default Awards;
