import React from "react";
import "./OurTeam.css";
import { getAssetPath } from "../../services/helpers";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const OurTeam = () => {
  const { t } = useTranslation();

  const { ourTeamBanner, leadership, threeSection, committeeSection, leadershipInfoModals } =
    t("ourTeam");

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="ourteam-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">
              {ourTeamBanner.heading}
            </h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{ourTeamBanner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{ourTeamBanner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {ourTeamBanner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* founders/chairmans */}
      <section className="simple-idea abt-pdtop cane-deve-bg">
        <div className="row mrg-0">
          <div className="container col-md-6">
            <h2 className="idea-title">{leadership.heading}</h2>
          </div>
          <div className="container col-md-10">
            <p className="idea-para abt-para-mrg">{leadership.para}</p>
          </div>
        </div>
        <div className="3-section">
          <div className="row mrg-0">
            <div className="col-md-4 leadership1-bg">
              <div className="container mb-3" style={{ zIndex: "9" }}>
                <h4 className="leadersec-title font-bold">
                  {threeSection.section1.heading}
                </h4>
                <p className="leadersec-sbtitle font-regular">
                  {threeSection.section1.para}
                </p>
                {i18next.language === "mr" ?<>
                <button
                  className="btn btn-default sec-lm-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#Modalbapulad"
                >
                  {threeSection.section1.btn}
                  <img
                    className="Arrow-white"
                    alt="arrow"
                    src={getAssetPath("assets/icons/Arrow-1.png")}
                  ></img>
                </button></> : null }
              </div>
            </div>
            <div className="col-md-4 leadership2-bg">
              <div className="container mb-3" style={{ zIndex: "9" }}>
                <h4 className="leadersec-title font-bold">
                  {threeSection.section2.heading}
                </h4>
                <p className="leadersec-sbtitle font-regular">
                  {threeSection.section2.para}
                </p>
                {i18next.language === "mr" ?<>
                <button
                  className="btn btn-default sec-lm-btn "
                  data-bs-toggle="modal"
                  data-bs-target="#Modalarunlad"
                >
                  {threeSection.section2.btn}
                  <img
                    className="Arrow-white"
                    alt="arrow"
                    src={getAssetPath("assets/icons/Arrow-1.png")}
                  ></img>
                </button></> : null }
              </div>
            </div>
            <div className="col-md-4 leadership3-bg">
              <div className="container mb-3" style={{ zIndex: "9" }}>
                <h4 className="leadersec-title font-bold">
                  {threeSection.section3.heading}
                </h4>
                <p className="leadersec-sbtitle font-regular">
                  {threeSection.section3.para}
                </p>
                {i18next.language === "mr" ?<>
                <button
                  className="btn btn-default sec-lm-btn "
                  data-bs-toggle="modal"
                  data-bs-target="#Modalsharadlad"
                >
                  {threeSection.section3.btn}
                  <img
                    className="Arrow-white"
                    alt="arrow"
                    src={getAssetPath("assets/icons/Arrow-1.png")}
                  ></img>
                </button></> : null }
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* other management members*/}
      <section className="company-overview">
        <div className="container">
          <div className="col-md-8">
            <h2 className="investor-title">
              {committeeSection.heading}
            </h2>
          </div>

          <div className="row mt-5">
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/2.tanajirao.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member2.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member2.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/3.dilip.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member3.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member3.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/4.anil.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member4.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member4.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/5.jayprakash.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member5.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member5.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/6.sukumar.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member6.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member6.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/7.vaibhav.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member7.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member7.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/8.satish_chaugule.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member8.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member8.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/9.sanjay.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member9.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member9.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/10.sheetal.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member10.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member10.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/11.prabhakar.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member11.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member11.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/12.avinash.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member12.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member12.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/13.Ramchandra.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member13.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member13.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/14.jitendra.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member14.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member14.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/15.sangram.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member15.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member15.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/16.balkrishna.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member16.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member16.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* ------------------------------------------------ */}
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/19.subhash.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member19.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member19.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/21.ashok.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member21.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member21.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/17.ashwini.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member17.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member17.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/18.anjana.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member18.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member18.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/22.vijay.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member22.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member22.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mngmt-membrs">
                <img
                  className="img-fluid"
                  alt="members"
                  src={getAssetPath("assets/imgs/ourteam-img/20.atmaram.jpg")}
                ></img>
                <div className="membrs-info">
                  <div className="container">
                    <h4 className="member-name font-bold">
                      {committeeSection.member20.memberName}
                    </h4>
                    <p className="member-pos font-regular">
                      {committeeSection.member20.memberPosition}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* leadership info modals */}
      <div
        className="modal fade"
        id="Modalbapulad"
        tabIndex="-1"
        aria-labelledby="Modalbapulad"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <a href="/" className="close" data-bs-dismiss="modal">
                <img
                  className="img-fluid"
                  alt="close-modal"
                  src={getAssetPath("assets/icons/close-modal.png")}
                ></img>
              </a>
              <div className="row">
                <div className="col-md-4">
                  <div className="leadership-modal-img">
                    <img
                      className="img-fluid w-100"
                      alt="bapu-lad"
                      src={getAssetPath("assets/imgs/ourteam-img/bapu-lad.png")}
                    ></img>
                  </div>
                </div>
                <div className="col-md-8 scroll-content">
                  <h2 className="leader-name-modal font-bold">
                    {leadershipInfoModals.modal1.name}
                  </h2>
                  <h5 className="leader-pos-modal font-regular">
                  {leadershipInfoModals.modal1.position}
                  </h5>

                  <p>
                  {leadershipInfoModals.modal1.para1}
                  </p>
                  {/* -------------------------------- */}
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading1}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para2}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading2}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para3}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading3}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para4}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading4}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para5}
                  </p>
                  <p>
                    {leadershipInfoModals.modal1.para6}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading5}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para7}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading6}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para8}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading7}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para9}
                  </p>
                  <p>
                    {leadershipInfoModals.modal1.para10}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading8}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para11}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading9}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para12}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading10}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para13}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading11}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para14}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading12}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para15}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading13}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para16}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading14}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para17}
                  </p>
                  {/* -------------------------------- */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal1.heading15}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal1.para18}
                  </p>
                  {/* -------------------------------- */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="Modalarunlad"
        tabIndex="-1"
        aria-labelledby="Modalarunlad"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <a href="/" className="close" data-bs-dismiss="modal">
                <img
                  className="img-fluid"
                  alt="close-modal"
                  src={getAssetPath("assets/icons/close-modal.png")}
                ></img>
              </a>
              <div className="row">
                <div className="col-md-4">
                  <div className="leadership-modal-img">
                    <img
                      className="img-fluid w-100"
                      alt="arun-lad"
                      src={getAssetPath("assets/imgs/ourteam-img/arun-lad.png")}
                    ></img>
                  </div>
                </div>
                <div className="col-md-8 scroll-content">
                  <h2 className="leader-name-modal font-bold">
                  {leadershipInfoModals.modal2.name}
                  </h2>
                  <h5 className="leader-pos-modal font-regular">
                  {leadershipInfoModals.modal2.position}
                  </h5>
                  <p>
                  {leadershipInfoModals.modal2.para1}
                  </p>

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal2.heading1}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal2.para2}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal2.heading2}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal2.para3}
                  </p>
                  <p>
                    {leadershipInfoModals.modal2.para4}
                  </p>
                  <p>
                    {leadershipInfoModals.modal2.para5}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal2.heading3}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal2.para6}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal2.heading4}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal2.para7}
                  </p>
                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal2.headingfour}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal2.para8}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal2.heading5}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal2.para9}
                  </p>
                  <p>
                    {leadershipInfoModals.modal2.para10}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal2.heading6}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal2.para11}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal2.heading7}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal2.para13}
                  </p>
                  {/* {/* --------------------------------} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="Modalsharadlad"
        tabIndex="-1"
        aria-labelledby="Modalsharadlad"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <a href="/" className="close" data-bs-dismiss="modal">
                <img
                  className="img-fluid"
                  alt="close-modal"
                  src={getAssetPath("assets/icons/close-modal.png")}
                ></img>
              </a>
              <div className="row">
                <div className="col-md-4">
                  <div className="leadership-modal-img">
                    <img
                      className="img-fluid w-100"
                      alt="sharad-lad"
                      src={getAssetPath("assets/imgs/ourteam-img/our-team-sharad.png")}></img>
                  </div>
                </div>
                <div className="col-md-8 scroll-content">
                  <h2 className="leader-name-modal font-bold">
                  {leadershipInfoModals.modal3.name}
                  </h2>
                  <h5 className="leader-pos-modal font-regular">{leadershipInfoModals.modal3.position}</h5>

                  <p>
                  {leadershipInfoModals.modal3.para1}
                  </p>

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading1}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para2}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading2}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para3}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading3}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para4}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading4}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para5}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading5}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para6}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading6}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para7}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading7}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para8}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading8}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para9}
                  </p>
                  {/* {/* --------------------------------} */}

                  <div className="bt-title font-semibold">
                    <p>{leadershipInfoModals.modal3.heading9}</p>
                  </div>
                  <p>
                    {leadershipInfoModals.modal3.para10}
                  </p>
                  {/* {/* --------------------------------} */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
