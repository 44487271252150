import React, { useEffect } from "react";
import "./TenderRegister.css";
import "./TenderLogin.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useRegisterQueryMutation } from "../../services/userAuthApi";
import {
  getLocalStorageData,
  storeData,
} from "../../services/localStorageService";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


const TenderRegister = () => {
  const { t } = useTranslation();
  const { heading, form, requiredMsg } = t("register");
  const navigate = useNavigate();
  useEffect(() => {
    const token = getLocalStorageData("token");
    if (token) {
      const roleId = getLocalStorageData("roleId");
      if (roleId === "1") {
        navigate("/FarmerDetails");
      } else if (roleId === "2") {
        navigate("/Tenders");
      }
    }
  });
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const [registerUser] = useRegisterQueryMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    // const userPass = data.get('name').charAt(0).toUpperCase()+data.get('name').slice(1)+"@123"
    const formData = {
      name: data.get("name"),
      email: data.get("email"),
      mobile: data.get("mobile"),
      company_name: data.get("company_name"),
      company_type: data.get("company_type"),
      state: data.get("state"),
      city: data.get("city"),
      address: data.get("address"),
      agree_terms: data.get("agree_terms") === "true" ? 1 : 0,
      role_type: data.get("role_type"),
      pincode: data.get("pincode"),
      lang: i18next.language
      //   password: userPass,
    };
    if (
      formData.name &&
      formData.email &&
      formData.email &&
      formData.mobile &&
      formData.company_name &&
      formData.company_type &&
      formData.state &&
      formData.city &&
      formData.address &&
      formData.pincode &&
      formData.agree_terms
    ) {
      const res = await registerUser(formData);
      if (res.data && res.data.data.status === "success") {
        storeData("token", res.data.data.token);
        storeData("roleId", data.get("role_type"));
        storeData(
          "userName",
          res.data.data.name.charAt(0).toUpperCase() +
            res.data.data.name.slice(1)
        );
        navigate("/Tenders");
      }
      if (res.error) {
        if (
          Array.isArray(res.error.data.message) &&
          res.error.data.message.length > 1
        ) {
          const errorMsg = (
            <ul>
              {res.error.data.message.map((msg, index) => (
                <li key={index}>{msg}</li>
              ))}
            </ul>
          );
          setError({ status: true, msg: errorMsg, type: "error" });
        } else {
          setError({
            status: true,
            msg: res.error.data.message,
            type: "error",
          });
        }
      }
    } else {
      setError({ status: true, msg: requiredMsg, type: "error" });
    }
  };


  return (
    <>
      <section className="login-bg">
        <div className="slider-login fslogin-bg">
          <div className="login-content">
            <div className="container">
              <div className="container login-form-row col-md-10 mg-tp">
                <p className="col-12 txt-center font-bold mb-3">
                  {heading.title}
                </p>
                <p className="register-link mb-3">
                  {heading.text1} <a href="TenderLogin">{heading.text2}</a>
                </p>
                {error.status ? (
                  <div className="alert alert-danger">{error.msg}</div>
                ) : (
                  ""
                )}
                <form id="registration-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <input
                      type="hidden"
                      id="role_type"
                      name="role_type"
                      value="2"
                    ></input>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          src={getAssetPath("assets/icons/user-icon.png")}
                        ></img>
                        <input
                          placeholder={form.name}
                          id="name"
                          name="name"
                          className="form-control rounded-0 font-regular"
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          src={getAssetPath("assets/icons/phone-icon.png")}
                        ></img>
                        <input
                          placeholder={form.number}
                          id="mobile"
                          name="mobile"
                          className="form-control rounded-0 font-regular"
                          type="number"
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          src={getAssetPath("assets/icons/mail-icon.png")}
                        ></img>
                        <input
                          placeholder={form.email}
                          id="email"
                          name="email"
                          className="form-control rounded-0 font-regular"
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          src={getAssetPath("assets/icons/company-icon.png")}
                        ></img>
                        <input
                          placeholder={form.companyName}
                          id="company_name"
                          name="company_name"
                          className="form-control rounded-0 font-regular"
                          type="text"
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-grp">
                        <img
                          className="user-icon"p
                          src={getAssetPath("assets/icons/company-icon.png")}
                        ></img>
                        <select
                          className="form-select rounded-0 font-regular"
                          aria-label="Default select example"
                          id="company_type"
                          name="company_type"
                        >
                          <option value="">{form.companyType}</option>
                          <option value="Type One">{form.type1}</option>
                          <option value="Type Two">{form.type2}</option>
                          <option value="Type Three">{form.type3}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          src={getAssetPath("assets/icons/state-icon.png")}
                        ></img>
                        <select
                          className="form-select rounded-0 font-regular"
                          aria-label="Default select example"
                          id="state"
                          name="state"
                        >
                          <option value="">{form.state}</option>
                          <option value="Maharashtra">{form.state1}</option>
                          <option value="Gujarat">{form.state2}</option>
                          <option value="Tamilnadu">{form.state3}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          src={getAssetPath("assets/icons/city-icon.png")}
                        ></img>
                        <select
                          className="form-select rounded-0 font-regular"
                          aria-label="Default select example"
                          id="city"
                          name="city"
                        >
                          <option value="">{form.city}</option>
                          <option value="Mumbai">{form.city1}</option>
                          <option value="Pune">{form.city2}</option>
                          <option value="Nashik">{form.city3}</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="input-grp">
                        <img
                          className="user-icon"
                          src={getAssetPath("assets/icons/pin-icon.png")}
                        ></img>
                        <input
                          placeholder={form.pincode}
                          id="pincode"
                          name="pincode"
                          className="form-control rounded-0 font-regular"
                          type="number"
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="txtarea-grp">
                        <textarea
                          placeholder={form.companyAddress}
                          className="form-control rounded-0 font-regular"
                          id="address"
                          name="address"
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                    <div className="form-check mb-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={true}
                        id="agree_terms"
                        name="agree_terms"
                      />

                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {form.T_C1}
                        <a href=""> {form.T_C2}</a>
                      </label>
                    </div>
                    <button
                      className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold"
                      type="submit"
                    >
                    {form.btn}
                      <img
                        className="Arrow-1"
                        src={getAssetPath("assets/icons/Arrow-1.png")}
                      ></img>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TenderRegister;
