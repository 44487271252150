import React from "react";
import { useTranslation } from "react-i18next";


const languages = [
{code:"en",lang:"EN"},
{code:"mr",lang:"मराठी"},
]


const LanguageSelector = () =>{

    const {i18n} = useTranslation();
    const changeLanguage = (lng) =>{
        i18n.changeLanguage(lng);
    }

return(
<div className="lang-switch form-check form-switch text-switch">
    { languages.map((lng) =>{
    return (
        <div key={lng.code}>
       {
        <div key={lng.code}>
          <input
            type="radio"
            id={`radio-${lng.code}`}
            name="language-switch"
            checked={lng.code === i18n.language}
            onChange={() => changeLanguage(lng.code)}
          />
          <label  htmlFor={`radio-${lng.code}`} style={lng.code === i18n.language ? { backgroundColor: '#FB5220', boxShadow: 'none', color: '#fff', borderRadius: '30px' } : {}}  > {lng.lang}</label>
        </div>
      }
    </div>
    )
    })}
</div>
)

}

export default LanguageSelector;
