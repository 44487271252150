import React, {useEffect, useState} from "react";
import "./FcCaneRegister.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation ,Trans } from "react-i18next";
import {useNavigate} from "react-router-dom";
import {getApi, getApiDiffHeaders, postApi} from "../../../services/api/api";
import {getLocalStorageData, storeData} from "../../../services/localStorageService";

const FcCaneRegister = () => {
    const { t } = useTranslation();
    const { bannerInfo, registerTable} = t("fccaneregister");
    const navigate = useNavigate();
    const [storedUserData, setStoredUserData] = useState(null);
    const [storedYearMaster, setStoredYearMaster] = useState(null);
    const [farmerCode, setFarmerCode] = useState(null);

    const [seasonSelected, setSeasonSelected] = useState(null);
    const [registrationList, setRegistrationList] = useState([]);
    const [totalArea, setTotalArea] = useState(0);

    useEffect(() => {
        if (setSeasonSelected !== null && storedUserData != null) {
            fetchAndSetRegistrationList().catch((error) => console.error("Error in fetchTonnageList:", error));
        }
    }, [seasonSelected]);

    useEffect(() => {
        const fetchLocalStorageData = async() => {
            try {
                const userData = JSON.parse(getLocalStorageData('userDetails')) ?? null;
                const yearMaster = JSON.parse(getLocalStorageData('seasonYears')) ?? [];
                const localStorageDate = getLocalStorageData('seasonDateId') ?? null;
                const fCode = getLocalStorageData('farmerCode') ?? null;

                setFarmerCode(fCode);
                setStoredUserData(userData);
                setStoredYearMaster(yearMaster);

                if (localStorageDate) {
                    setSeasonSelected(localStorageDate);
                } else if (Array.isArray(yearMaster) && yearMaster.length > 0) {
                    setSeasonSelected(yearMaster[0]?.desc_en);
                }
            } catch (error) {
                console.error('Failed to fetch local storage data:', error);
            }
        };

        fetchLocalStorageData();
    }, []);

    const handleSeasonDateSelection = (season) => {
        setSeasonSelected(season);
        storeData('seasonDateId', season);
    };

    const fetchAndSetRegistrationList = async () => {
        const actualData = {
            season_yr: seasonSelected,
            farmer_code: farmerCode,
        };

        try {
            const caneInfoResponse = await getApiDiffHeaders('getcaneinfo/', actualData);
            // Check if response is valid and contains expected data
            if (caneInfoResponse && caneInfoResponse.registrations) {
                const registrationArray = caneInfoResponse.registrations;
                setRegistrationList(registrationArray);
                setTotalArea(caneInfoResponse.total_area  ?? null); // Optional chaining for safety
            } else {
                // Handle case where response structure is not as expected
                setRegistrationList([]); // Or another appropriate default state
                setTotalArea(null);
                console.warn('Unexpected response structure:', caneInfoResponse);
            }
        } catch (error) {
            console.error('Failed to fetch getcaneinfo data:', error);
            // Optionally, set an error state to display to the user
        }
    };

    const handleToRedirectLocation = (path, regId = null) => {
        navigate(path, regId ? { state: { regId} } : {});
    };

    const getDescEnBySeasonIdgetDescEnBySeasonId = (seasonId) => {
        let season = storedYearMaster.find(season => season.season_yr_id === parseInt(seasonId));

        return season ? season.desc_en : null; // Return desc_en if found, otherwise return null
    };

    return (
        <>
        <div className="Tenders-headbanner">
            <div className="Farmer-banner col-md-12">
                <div className="container">
                    <h1 className="headingtenders font-bold">{bannerInfo.heading}</h1>
                    <div className="breadcrum">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-container">
                                <li className="breadcrumb-item brdm-itm">
                                    <button
                                        onClick={() => handleToRedirectLocation('/home')}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'inherit', // Use inherited color from parent
                                            textDecoration: 'underline', // Makes it look like a link
                                            cursor: 'pointer',
                                        }}
                                        tabIndex={0} // Make it focusable for keyboard users
                                    >
                                        {bannerInfo.link}
                                    </button>
                                </li>
                                <li
                                    className="breadcrumb-item brdm-itm"
                                    aria-current="page">
                                    <button
                                        onClick={() => handleToRedirectLocation('/fc-dashboard')}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'inherit', // Use inherited color from parent
                                            textDecoration: 'underline', // Makes it look like a link
                                            cursor: 'pointer',
                                        }}
                                        tabIndex={0} // Make it focusable for keyboard users
                                    >
                                        {bannerInfo.text}
                                    </button>
                                </li>
                                <li
                                    className="breadcrumb-item brdm-itm active"
                                    aria-current="page">
                                    {bannerInfo.text2}
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

            <section className="tender-table">
                <div className="tendermain-table">
                    <div className="col-md-12">
                        <div className="famrer-logout mb-3">

                            <button
                                onClick={() => {
                                    handleToRedirectLocation('/fc-dashboard')
                                }}
                                className="btn btn-default btn-round tender-Register btn-back"
                        >
                            <img
                                className="back"
                                alt="back"
                                src={getAssetPath("assets/icons/back-arrow.png")}
                            ></img>
                            {registerTable.back}
                        </button>
                    </div>
                    <div className="login-search mb-30">
                        <div className="farmer-name">
                                <div className="username">
                                    <h2 className="font-bold">
                                        {registerTable.tablename}
                                    </h2>
                                </div>
                            {
                                totalArea ?
                                    <p className="dash-text regular-font">
                                        {registerTable.para}: {totalArea} (Hectors)
                                    </p>:null
                            }

                        </div>
                        <div className="season-selectn">
                            <p className="selectreg-title font-regular">
                                {registerTable.selectTitle}
                            </p>

                            {
                                storedYearMaster != null ?
                                    <div className="dropdown">
                                        {
                                            seasonSelected ?

                                        <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            Season {getDescEnBySeasonIdgetDescEnBySeasonId(seasonSelected)}
                                        </button>
                                                :null}
                                        <ul
                                            className="dropdown-menu drpdown-height"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            {storedYearMaster && storedYearMaster.map((season) => (
                                                <li onClick={()=>{handleSeasonDateSelection(season.season_yr_id)}}>
                                                    <a className="dropdown-item">
                                                        Season {season.desc_en}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    :null
                            }


                        </div>
                    </div>

                    <div className="table-responsive mb-3">
                        <table className="table table-striped table-hover ">
                            <thead className="thead-dark th-height">
                            <tr>
                                <th scope="col">Registration No</th>
                                <th scope="col">Survey Number</th>
                                <th scope="col">Season</th>
                                <th scope="col">Cane Area</th>
                                <th scope="col">Road name</th>
                                <th scope="col">Fieldman Name</th>

                                <th scope="col">{registerTable.tableHead.text6}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Array.isArray(registrationList) && registrationList.length > 0 ? (
                                registrationList.map((singleReg) => (
                                    <tr className="tr-height"
                                        key={singleReg.reg_id}> {/* Make sure to use a unique key */}
                                        <td>{singleReg.reg_no}</td>
                                        <td>{singleReg.survey_no}</td>
                                        <td>{singleReg.hangam_name}</td>
                                        <td>{singleReg.cane_area}</td>
                                        <td>{singleReg.route_name}</td>
                                        <td>{singleReg.fieldman_fname ?? ''} {singleReg.fieldman_lname ?? ''}</td>
                                        <td>
                                                <button
                                                    onClick={() => {handleToRedirectLocation('/fc-register-detail',singleReg.reg_id)}}
                                                    className="btn btn-default btn-round Download"
                                                >
                                                    {registerTable.buttons.viewdetail}
                                                </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8">
                                        <p className="result-text text-center regular-font">No Cane Registration Details Found</p>
                                    </td>
                                </tr>

                            )}
                            </tbody>
                        </table>
                    </div>
                    {
                        Array.isArray(registrationList) && registrationList.length > 0 ?
                            <p className="result-text text-center regular-font">{registerTable.results.text1} {registrationList.length} {registerTable.results.text2}</p>
                            :null
                    }
                </div>
            </div>
        </section>
        </>
    )
};

export default FcCaneRegister
