import React, {useEffect, useState} from 'react';
import moment from 'moment';
import "./FcCaneBill.css";
const DetailModal = ({ isOpen, onClose, data }) => {

    const [deductionAmt, setDeductionAmt] = useState(0);

    const formatAmount = (amount) => {
        return new Intl.NumberFormat('en-US').format(amount);
    };

    // Extract deductions from the passed data
    const deductions = data?.deductions ? Object.values(data.deductions) : [];

    useEffect(() => {
        if (deductions.length > 0) {
            let totalPayableAmount = 0;
            for (let i = 0; i < deductions.length; i++) {
                totalPayableAmount += deductions[i].Payable_Amount;
            }
            setDeductionAmt(totalPayableAmount);
        }
    }, [deductions]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay cstm-overlay">
            <div className="modal-content cstm-content">
                <h2>Details</h2>
                <div className="modal-body cstm-body">
                    {/* Left Side for Details */}
                    <div className="details-section">
                        <p><strong>Bill Date:</strong> {moment(data.Cane_Bill_Date).format('MMMM Do YYYY')}</p>
                        <p><strong>Period From:</strong> {moment(data.Cane_Bill_Period_From).format('MMMM Do YYYY')}</p>
                        <p><strong>Period To:</strong> {moment(data.Cane_Bill_Period_To).format('MMMM Do YYYY')}</p>
                        <p><strong>Bill Type:</strong> {data.Cane_Bill_Type}</p>
                        <p><strong>Bill ID:</strong> {data.Cane_Bill_ID}</p>
                        <p><strong>Bill Tonnage:</strong> {data.Bill_Tonnage}</p>
                        <p><strong>Bill Rate:</strong> {formatAmount(data.Bill_Rate)}</p>
                        <p><strong>Bill Amount:</strong> {formatAmount(data.Bill_Amount)}</p>
                        <p><strong>Total Deduction Amount:</strong> {formatAmount(deductionAmt)}</p>
                        <p><strong>Payable Amount:</strong> {formatAmount(data.Payable_Amount)}</p>
                        <p><strong>Current Bank Name:</strong> {data.Current_Bank_Name_English}</p>
                        <p><strong>Farmer Name:</strong> {data.Farmer_Name_English}</p>
                    </div>

                    {/* Right Side for Deductions */}
                    {deductions.length > 0 ? (
                        <div className="deductions-section">
                            <h3>Deductions</h3>
                            <div className="deductions-table-container">
                                <table className="deductions-table">
                                    <thead>
                                    <tr>
                                        <th>Deduction Type</th>
                                        <th>Narration</th>
                                        <th>Bill Tonnage</th>
                                        <th>Bill Rate</th>
                                        <th>Bill Amount</th>
                                        <th>Deduction Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {deductions.map((deduction, index) => (
                                        <tr key={index}>
                                            <td>{deduction.Deduction_Type}</td>
                                            <td>{deduction.Narration}</td>
                                            <td>{deduction.Bill_Tonnage}</td>
                                            <td>{formatAmount(deduction.Bill_Rate)}</td>
                                            <td>{formatAmount(deduction.Bill_Amount)}</td>
                                            <td>{formatAmount(deduction.Payable_Amount)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ) : (
                        <div className="no-deductions">
                            <p>No deductions available.</p>
                        </div>
                    )}
                </div>

                <button className="btn btn-default btn-round tender-submit mt-3" onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default DetailModal;
