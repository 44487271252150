import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userAuthApi = createApi({
  reducerPath: "userAuthApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://krantisugar.com/kranti-sugar/public/api/v1" }),
  endpoints: (builder) => ({
    registerQuery: builder.mutation({
      query: (user) => {
        return {
          url: "register",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    loginQuery: builder.mutation({
      query: (user) => {
        return {
          url: "login",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    logoutQuery: builder.mutation({
      query: ({token}) => {
        return {
          url: "logout",
          method: "POST",
          body: {},
          headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`,
          },
        };
      },
    }),
    getLoggedUser: builder.query({
      query: ({token}) => {
        return {
          url: 'show-list',
          method: 'POST',
          body: {},
          headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`,
          }
        }
      }
    }),
    getSingleTenderDetail: builder.query({
      query: ({token ,tenderId}) => {
        return {
          url: `tender-detail/${tenderId}`,
          method: 'GET',
          headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`,
          }
        }
      }
    }),
    uploadSupplierAttachment: builder.mutation({
      query: (data) => {
        return {
          url: 'apply-tender',
          method: 'POST',
          body: data.formData,
          formData: true,
          headers: {
            'Authorization': `Bearer ${data.token}`,
          }
        }
      }
    }),
    getPagesAccess: builder.query({
      query: ({token}) => {
        return {
          url: 'access-pages',
          method: 'GET',
          headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${token}`,
          }
        }
      }
    }),
    getHomePageDetail: builder.query({
      query: (language) => {
        return {
          url: `home-page/${language}`,
          method: 'GET',
          headers: {
            "Content-type": "application/json",
          }
        }
      }
    }),
    getTenderList: builder.query({
      query: () => {
        return {
          url: 'tenders',
          method: 'GET',
          headers: {
            "Content-type": "application/json",
          }
        }
      }
    }),
    sendResetLinkEmail: builder.mutation({
      query: ({email ,lang}) => {
        return {
          url: "password/email",
          method: "POST",
          body: {email,lang},
          headers: {
            "Content-type": "application/json",
          }
        }
      }
    }),
    resetPassword: builder.mutation({
      query: (data) => {
        return {
          url: "password/reset",
          method: "POST",
          body: data.formData,
          formData: true
        }
      }
    }),
    getBrochureList: builder.query({
      query: () => {
        return {
          url: 'brochure-list',
          method: 'GET',
          headers: {
            "Content-type": "application/json",
          }
        }
      }
    }),
    contactUsQuery: builder.mutation({
      query: (user) => {
        return {
          url: "contact-us",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
  }),
});

export const {
  useRegisterQueryMutation,
  useLoginQueryMutation,
  useLogoutQueryMutation,
  useGetLoggedUserQuery,
  useGetSingleTenderDetailQuery,
  useUploadSupplierAttachmentMutation,
  useGetPagesAccessQuery,
  useGetHomePageDetailQuery,
  useGetTenderListQuery,
  useSendResetLinkEmailMutation,
  useResetPasswordMutation,
  useGetBrochureListQuery,
  useContactUsQueryMutation
} = userAuthApi;