export const imageNames = [
    'new-articles (1).png',
    'new-articles (2).png',
    'new-articles (3).png',
    'new-articles (4).png',
    'new-articles (5).png',
    'new-articles (6).png',
    'new-articles (7).png',
    'new-articles (8).png',
    'new-articles (9).png',
    'new-articles (10).png',
    'new-articles (11).png',
    'new-articles (12).png',
    'new-articles (13).png',
    'new-articles (14).png',
    'new-articles (15).png',
    'new-articles (16).png',
    'new-articles (17).png',
    'new-articles (18).png',
    'new-articles (19).png',
    'new-articles (20).png',
    'new-articles (21).png',
    'new-articles (22).png',
    'new-articles (23).png',
    'new-articles (24).png',
    'new-articles (25).png',
    'new-articles (26).png',
    'new-articles (27).png',
    'new-articles (28).png',
    'new-articles (29).png',
    'new-articles (30).png',
    'new-articles (31).png',
    'new-articles (32).png',
    'new-articles (33).png',
    'new-articles (34).png',
    'new-articles (35).png',
    'new-articles (36).png',
    'new-articles (37).png',
    'new-articles (38).png',
    'new-articles (39).png',
    'new-articles (40).png',
    'new-articles (41).png',
    'new-articles (42).png',
    'new-articles (43).png',
    'new-articles (44).png',
    'new-articles (45).png',
    'new-articles (46).png',
    'new-articles (47).png',
    'new-articles (48).png',
    'new-articles (49).png',
    'new-articles (50).png',
    'new-articles (51).png',
    'new-articles (52).png',
    'new-articles (53).png',
    'new-articles (54).png',
    'new-articles (55).png',
  ];