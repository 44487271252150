import React, { useEffect, useState } from "react";
import "./Tenders.css";
import {
  useLogoutQueryMutation,
  useGetLoggedUserQuery,
  useGetPagesAccessQuery,
} from "../../services/userAuthApi";
import {
  getLocalStorageData,
  removeLocalStorageData,
} from "../../services/localStorageService";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { downloadClick } from "../../services/commonFunction";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";

const Tenders = () => {

  const { t } = useTranslation();

  const { banner, tenderTable } = t("tenders")

  const navigate = useNavigate();
  if (!getLocalStorageData("token") && getLocalStorageData("roleId") !== 2) {
    navigate("/TenderLogin");
  }
  const [searchTerm, setSearchTerm] = useState("");
  const [tenderData, setTenderData] = useState("No Record");
  const token = getLocalStorageData("token");
  const loginedUserName = getLocalStorageData("userName");
  //start access page based on Role id
  const [hasPageAccess, setPageAccess] = useState(false);
  const { data: pageAccess, isSuccess: getAccessSuccess } =
    useGetPagesAccessQuery({ token });
  useEffect(() => {
    if (getAccessSuccess) {
      const pathname = window.location.pathname;
      const pageExists = pageAccess.some((page) => pathname.includes(page));
      setPageAccess(pageExists);
    }
  }, [pageAccess, getAccessSuccess]);
  //end access page based on Role id
  const [logoutUser] = useLogoutQueryMutation();
  const {
    data: showTenderList,
    isSuccess,
    isLoading,
  } = useGetLoggedUserQuery({ token });

  const handleLogout = async () => {
    const res = await logoutUser({ token });
    if (res.data.message === "success") {
      removeLocalStorageData();
      navigate("/TenderLogin");
    }
  };

  const handleButtonClick = (tenderId) => {
    navigate(`TenderApply/${tenderId}`);
  };
  const handleDownload = (tender) => {
    const url = tender.attachment[0].attachment;
    let fileName = url.substring(url.lastIndexOf("/") + 1);
    downloadClick(url, fileName);
  };

  //pagination
  const [pageNumber, setPageNumber] = useState(0); //when click which page
  const tenderPerPage = 10;
  const pageVisited = pageNumber * tenderPerPage;
  let tenderList;
  if (tenderData === "No Record") {
    tenderList = (
      <tr>
        <td colSpan="5">No Record</td>
      </tr>
    );
  } else {
    tenderList = tenderData
      .slice(pageVisited, pageVisited + tenderPerPage)
      .filter((item) => {
        const trimmedSearchTerm = searchTerm.trim().toLowerCase();
        if (trimmedSearchTerm === "") {
          return item;
        } else {
          return (
            item.title.toLowerCase().includes(trimmedSearchTerm) ||
            item.description.toLowerCase().includes(trimmedSearchTerm) ||
            item.start_date.toString().includes(searchTerm)
          );
        }
      })
      .map((tender, index) => (
        <tr className="tr-height" key={index + 1}>
          <td>{tender.start_date}</td>
          <td>{tender.title}</td>
          <td colSpan="2">{tender.description}</td>
          <td>
            <button
              className="btn btn-default btn-round Download"
              onClick={() => handleDownload(tender)}
            >
              {tenderTable.buttons.Download}
            </button>
          </td>
          <td>
            <button
              key={index}
              className="btn btn-default btn-round apply-now"
              disabled={
                tender.bidding_exists !== null || tender.tender_status === 2
              }
              onClick={() => handleButtonClick(tender.id)}
            >
              {tender.tender_status === 2
                ? `${tenderTable.buttons.closed}`
                : tender.bidding_exists === 0
                  ? `${tenderTable.buttons.applied}`
                  : tender.bidding_exists === 1
                    ? `${tenderTable.buttons.accepted}`
                    : tender.bidding_exists === 2
                      ? `${tenderTable.buttons.rejected}`
                      : `${tenderTable.buttons.apply}`
              }
            </button>
          </td>
        </tr>
      ));
  }
  const pageCount = Math.ceil(tenderData.length / tenderPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  //pagination end

  useEffect(() => {
    if (showTenderList && isSuccess) {
      const response = showTenderList.data;
      setTenderData(response);
    }
  }, [showTenderList, isSuccess]);
  if (isLoading) return <div>Loading...</div>;
  return (
    <>
      {hasPageAccess ? (
        <div>
          <div className="Tenders-headbanner">
            <div className="Tenders-banner col-md-12">
              <div className="container">
                <h1 className="headingtenders font-bold">{banner.title}</h1>
                <div className="breadcrum">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-container">
                      <li className="breadcrumb-item brdm-itm">
                        <a href="/home">{banner.link}</a>
                      </li>
                      <li
                        className="breadcrumb-item brdm-itm active"
                        aria-current="page"
                      >
                        {banner.text}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <section className="tender-table">
            <div className="">
              <div className="tendermain-table">
                <div className="col-md-12">
                  <div className="login-search">
                    {!getLocalStorageData("token") &&
                    getLocalStorageData("roleId") !== 1 ? (
                      <div className="login-register">
                        <p className="appl-text regular-font">
                         {tenderTable.text1}
                        </p>
                        <button className="btn btn-default btn-round tender-login">
                        {tenderTable.btn1}
                        </button>
                        <p className="appl-text regular-font">{tenderTable.text2}</p>
                        <button className="btn btn-default btn-round tender-register">
                        {tenderTable.btn2}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="username">
                      <h2 className="font-bold">
                        {tenderTable.greetings} <span>{loginedUserName}</span>
                      </h2>
                      <button
                        className="btn btn-default btn-round tender-Register"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCenter"
                      >
                        <img
                          className="back"
                          alt="back"
                          src={getAssetPath("assets/icons/logout.png")}
                        ></img>
                      </button>
                    </div>
                    <div className="input-grp search-inp w-50">
                      <img
                        className="user-icon"
                        alt="icon"
                        src={getAssetPath("assets/icons/search-icon.png")}
                      ></img>
                      <input
                        placeholder={tenderTable.search}
                        id="search"
                        name="search"
                        className="form-control rounded-0 font-regular"
                        type="text"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      ></input>
                    </div>
                  </div>
                  <div className="table-responsive mb-3">
                    <table className="table table-striped table-hover ">
                      <thead className="thead-dark th-height">
                        <tr>
                          <th scope="col">{tenderTable.tableHead.text1}</th>
                          <th scope="col">{tenderTable.tableHead.text2}</th>
                          <th scope="col" colSpan="2">
                          {tenderTable.tableHead.text3}
                          </th>
                          <th scope="col">{tenderTable.tableHead.text4}</th>
                          <th scope="col">{tenderTable.tableHead.text5}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tenderList.length === 0 ? (
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={6}>
                              <div className="no-record-found">
                                <img
                                  className="not-found-img img-fluid"
                                  alt="not-found"
                                  src={getAssetPath(
                                    "assets/imgs/not-found-img.png"
                                  )}
                                ></img>
                                {tenderTable.tableBody.text1}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          tenderList
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="table-pagination">
                    <p className="appl-text regular-font"></p>

                    <nav aria-label="Page navigation example">
                      <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={changePage}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      ></ReactPaginate>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            className="modal "
            id="exampleModalCenter"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 mr-auto">
                      <div className="confirm-bg">
                        <img
                          className="sucess"
                          alt="sucess"
                          src={getAssetPath("assets/icons/caution.png")}
                        ></img>
                        <h2 className="sucess-title font-bold">
                          {tenderTable.closingModal.heading}
                        </h2>
                        <p className="sucess-sbtxt font-regular">
                        {tenderTable.closingModal.para}
                        </p>
                        <div className="confirm-btns">
                          <button
                            className="btn btn-default btn-round cancel-logout"
                            data-bs-dismiss="modal"
                          >
                            {tenderTable.closingModal.btn1}
                          </button>
                          <button
                            className="btn btn-default btn-round tender-submit"
                            data-bs-dismiss="modal"
                            onClick={handleLogout}
                          >
                            {tenderTable.closingModal.btn2}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
         `${tenderTable.warning}`
      )}
    </>
  );
};

export default Tenders;



