import React, {useEffect} from "react";
import "./FcDashboard.css";
import {getAssetPath} from "../../../services/helpers";
import {useTranslation, Trans} from "react-i18next";
import {getApi} from "../../../services/api/api";
import {getLocalStorageData, removeKeyData, storeData} from "../../../services/localStorageService";
import {useNavigate} from "react-router-dom";

const FcDashboard = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {bannerInfo, farmerCorner, fourSections} = t("fcdashboard");
    const storedUserData = JSON.parse(getLocalStorageData('userDetails')) ?? null;

    useEffect(() => {
        if (storedUserData == null) {
            try {
                navigate("/FarmerInfo");
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        }
    }, []);

    // Function to handle logout click
    const handleLogoutClick = () => {
        removeKeyData('userDetails', null);
        navigate("/FarmerInfo");
    };
    const handleToRedirectLocation = (path) => {
        navigate(path);
    };

    return (
        <>
            <div className="Tenders-headbanner">
                <div className="Farmer-banner col-md-12">
                    <div className="container">
                        <h1 className="headingtenders font-bold">{bannerInfo.heading}</h1>
                        <div className="breadcrum">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-container">
                                    <li className="breadcrumb-item brdm-itm">
                                        <button
                                            onClick={() => handleToRedirectLocation('/home')}
                                            style={{
                                                background: 'none',
                                                border: 'none',
                                                color: 'inherit', // Use inherited color from parent
                                                textDecoration: 'underline', // Makes it look like a link
                                                cursor: 'pointer',
                                            }}
                                            tabIndex={0} // Make it focusable for keyboard users
                                        >
                                            {bannerInfo.link}
                                        </button>
                                    </li>
                                    <li
                                        className="breadcrumb-item brdm-itm active"
                                        aria-current="page">
                                        {bannerInfo.text}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


            {/* farmer dashboard */}
            {
                storedUserData != null ?
                    <section className="simple-idea welcome-dashboard">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="login-search mb-25">
                                        <div className="farmer-name">
                                            <div className="username">
                                                <h2 className="font-bold">
                                                    {farmerCorner.welcome}{" "}
                                                    <span>{storedUserData.fname_ma ?? null} {storedUserData.mname_ma ?? null} {storedUserData.lname_ma ?? null}</span>
                                                </h2>
                                            </div>
                                            <p className="dash-text regular-font">
                                                {farmerCorner.para}
                                            </p>
                                        </div>
                                        <div className="farmer-logout">
                                            <button
                                                className="btn btn-default btn-round tender-Register btn-logout"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModalCenter"
                                            >
                                                {farmerCorner.logout}
                                                <img
                                                    className="back"
                                                    alt="back"
                                                    src={getAssetPath("assets/icons/logout-fc.png")}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* 4 services sections */}
                        <div className="foursections">
                            <div className="row mrg-0">
                                {/* cane register req */}
                                <div className="col-md-3 cane-req">
                                    <div className="container mb-3 indx">
                                        <h4 className="service-title font-bold">
                                            {fourSections.section1.heading}
                                        </h4>
                                        <button
                                            onClick={() => {
                                                handleToRedirectLocation('/fc-cane-req')
                                            }}
                                            className="btn btn-default sec-lm-btn ">
                                            {fourSections.section1.btn}
                                            <img
                                                className="Arrow-white"
                                                alt="arrow"
                                                src={getAssetPath("assets/icons/Arrow-1.png")}
                                            ></img>
                                        </button>
                                    </div>
                                </div>

                                {/* cane register */}
                                <div className="col-md-3 cane-reg">
                                    <div className="container mb-3 indx">
                                        <h4 className="service-title font-bold">
                                            {fourSections.section2.heading}
                                        </h4>
                                        <button
                                            onClick={() => {
                                                handleToRedirectLocation('/fc-cane-register')
                                            }}
                                            className="btn btn-default sec-lm-btn ">
                                            {fourSections.section2.btn}
                                            <img
                                                className="Arrow-white"
                                                alt="arrow"
                                                src={getAssetPath("assets/icons/Arrow-1.png")}
                                            ></img>
                                        </button>
                                    </div>
                                </div>

                                {/* cane weight */}
                                <div className="col-md-3 cane-weight">
                                    <div className="container mb-3 indx">
                                        <h4 className="service-title font-bold">
                                            {fourSections.section3.heading}
                                        </h4>
                                        <button
                                            onClick={() => {
                                                handleToRedirectLocation('/fc-cane-weight')
                                            }}
                                            className="btn btn-default sec-lm-btn ">
                                            {fourSections.section3.btn}
                                            <img
                                                className="Arrow-white"
                                                alt="arrow"
                                                src={getAssetPath("assets/icons/Arrow-1.png")}
                                            ></img>
                                        </button>
                                    </div>
                                </div>

                                {/* cane bill */}
                                <div className="col-md-3 cane-bill">
                                    <div className="container mb-3 indx">
                                        <h4 className="service-title font-bold">
                                            {fourSections.section4.heading}
                                        </h4>
                                        <button
                                            onClick={() => {
                                                handleToRedirectLocation('/fc-cane-bill')
                                            }}
                                            className="btn btn-default sec-lm-btn ">
                                            {fourSections.section4.btn}
                                            <img
                                                className="Arrow-white"
                                                alt="arrow"
                                                src={getAssetPath("assets/icons/Arrow-1.png")}
                                            ></img>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> : null
            }


            {/* logout modal */}
            <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 mr-auto">
                                    <div className="confirm-bg">
                                        <img
                                            className="sucess"
                                            alt="sucess"
                                            src={getAssetPath("assets/icons/caution.png")}
                                        ></img>
                                        <h2 className="sucess-title font-bold mb-0">{farmerCorner.logoutModal.heading}</h2>
                                        <p className="sucess-sbtxt font-regular">
                                            {farmerCorner.logoutModal.msg}
                                        </p>
                                        <div className="confirm-btns">
                                            <button
                                                className="btn btn-default btn-round cancel-logout"
                                                data-bs-dismiss="modal"
                                            >
                                                {farmerCorner.logoutModal.no}
                                            </button>
                                            <button
                                                className="btn btn-default btn-round tender-submit"
                                                data-bs-dismiss="modal"
                                                onClick={handleLogoutClick}  // Add the onClick handler here
                                            >
                                                {farmerCorner.logoutModal.yes}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FcDashboard
