import React, { useState } from "react";
import "./Engineering.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../services/helpers";
import { Trans, useTranslation } from "react-i18next";
import { imageNames } from "./staticImageArray";

const Engineering = () => {

    const { t } = useTranslation();

    const { containerHeading,containerHeading1,breadcrumbItem1,breadcrumbItem2,breadcrumbItem3,containerPara1,containerPara2,containerPara3,subtitle,titlePara,list } = t("engineering")

    // var EngineeringImgs = {
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     speed: 500,
    //     fade: true,
    //     cssEase: "linear",
    //     dots: true,
    //     infinite: true,
    //     arrows: false,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    
    //   }
    const [selectedImage, setSelectedImage] = useState(null);
    const openModal = (imageName) => {
        setSelectedImage(imageName);
    }

  return (
    <>
      <div className="Tenders-headbanner">
          <div className="distillery-banner col-md-12">
          <div className="container">
              <h1 className="headingtenders font-bold">{containerHeading}</h1>
              <div className="breadcrum">
              <nav aria-label="breadcrumb">
                  <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                      <a href="/home">{breadcrumbItem1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                      <a href="/home">{breadcrumbItem2}</a>
                  </li>
                  <li
                      className="breadcrumb-item brdm-itm active"
                      aria-current="page"
                  >
                      {breadcrumbItem3}
                  </li>
                  </ol>
              </nav>
              </div>
          </div>
          </div>
      </div>

      <section className="ngfc-bg">
            <div className="container">
                <div className="newsroom">
                    <div className="row mb-5 d-flex justify-content-center">
                        <div className="col-md-6">
                            <h2 className="investor-title mb-4">{containerHeading}</h2>
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara1}
                            components={{1: <br/> }}></Trans>
                            </p>
                        </div>
                        <div className="col-md-6 img-flex">
                            {/* <div className="agriculture-multimg">
                                <Slider {...EngineeringImgs}>
                                    <div className="img-item">
                                        <img
                                            className="department-img"
                                            alt="enginnering"
                                            src={getAssetPath("assets/imgs/department/enginnering/eng-img1.jpeg")}
                                        ></img>
                                    </div>
                                    <div className="img-item">
                                        <img
                                            className="department-img"
                                            alt="enginnering"
                                            src={getAssetPath("assets/imgs/department/enginnering/eng-img2.jpeg")}
                                        ></img>
                                    </div>
                                    <div className="img-item">
                                        <img
                                            className="department-img"
                                            alt="enginnering"
                                            src={getAssetPath("assets/imgs/department/enginnering/eng-img3.jpeg")}
                                        ></img>
                                    </div>
                                    <div className="img-item">
                                        <img
                                            className="department-img"
                                            alt="enginnering"
                                            src={getAssetPath("assets/imgs/department/enginnering/eng-img4.jpeg")}
                                        ></img>
                                    </div>
                                    <div className="img-item">
                                        <img
                                            className="department-img"
                                            alt="enginnering"
                                            src={getAssetPath("assets/imgs/department/enginnering/eng-img5.jpeg")}
                                        ></img>
                                    </div>
                                    <div className="img-item">
                                        <img
                                            className="department-img"
                                            alt="enginnering"
                                            src={getAssetPath("assets/imgs/department/enginnering/eng-img6.jpeg")}
                                        ></img>
                                    </div>
                                    <div className="img-item">
                                        <img
                                            className="department-img"
                                            alt="enginnering"
                                            src={getAssetPath("assets/imgs/department/enginnering/eng-img7.jpeg")}
                                        ></img>
                                    </div>
                                    <div className="img-item">
                                        <img
                                            className="department-img"
                                            alt="enginnering"
                                            src={getAssetPath("assets/imgs/department/enginnering/eng-img8.jpeg")}
                                        ></img>
                                    </div>
                                </Slider>
                            </div> */}
                            <img
                                className="department-img"
                                alt="administration"
                                src={getAssetPath("assets/imgs/department/enginnering/Eng-img1.jpeg")}
                            ></img>
                        </div>
                    </div>
                    <div className="row mb-5 d-flex justify-content-center">
                        <div className="col-md-6 img-flex-menu">
                            <img
                                className="department-img"
                                alt="administration"
                                src={getAssetPath("assets/imgs/department/enginnering/Eng-img8.jpeg")}
                            ></img>
                        </div>
                        <div className="col-md-6">
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara2}
                            components={{1: <br/> }}></Trans>
                            </p>
                        </div>
                    </div>
                    <div className="row mb-4 d-flex justify-content-center">
                        <div className="col-md-6">
                            <p className="department-sbtxt ">
                            <Trans
                            i18nKey={containerPara3}
                            components={{1: <br/> }}></Trans>
                            </p>
                        </div>
                        <div className="col-md-6 img-flex">
                            <img
                                className="department-img"
                                alt="administration"
                                src={getAssetPath("assets/imgs/department/enginnering/Eng-img6.jpeg")}
                            ></img>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="ngfc-bg" style={{backgroundColor : "#f6f6f6"}}>
            <div className="container">
                <div className="newsroom">
                    <h2 className="investor-title mb-4">{containerHeading1}</h2>
                    <div className="row">
                    {imageNames.map((imageName, index) => (
                        <div className="col-md-4">
                            <div className="deve-facility">
                                <div className="cane-facility-img" data-bs-toggle="modal" data-bs-target="#Modalimg" key={index}  onClick={() => openModal(imageName)}>
                                    <img className="img-fluid gallery-img" src={getAssetPath(`assets/imgs/department/enginnering/${imageName}`)} alt={`engineering ${index + 1}`} />
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        </section>

        <div className="modal fade" id="Modalimg" tabIndex="-1" aria-labelledby="Modalimg" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <a href="/" className="close" data-bs-dismiss="modal">
                            <img className="img-fluid" alt="close-modal" src={getAssetPath("assets/icons/close-modal.png")}></img>
                        </a>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="leadership-modal-img">
                                    <img className="img-fluid ks-gallery-img w-100" alt="enginnering" src={getAssetPath(`assets/imgs/department/enginnering/${selectedImage}`)}></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
  );
};

export default Engineering;
