export const imageNames = [

    // 'gallery1.png',
    // 'gallery2.png',
    // 'gallery7.png',
    // 'gallery8.png',
    // 'gallery9.png',
    // 'gallery10.png',
    // 'gallery11.png',
    'ks_(1).jpg',
    'ks_(2).JPG',
    'ks_(3).jpg',
    'ks_(4).jpg',
    'ks_(5).jpg',
    'ks_(6).jpg',
    'ks_(7).jpg',
    'ks_(8).jpg',
    'ks_(9).jpg',
    'ks_(10).jpg',
    'ks_(11).jpg',
    'ks_(12).jpg',
    'ks_(13).jpg',
    'ks_(14).jpg',
    'ks_(15).jpg',
    'ks_(16).jpg',
    'ks_(17).jpg',
    'ks_(18).jpg',
    'ks_(19).jpg',
    'ks_(20).jpg',
    'ks_(21).jpg',
    'ks_(22).jpg',
    'ks_(23).jpg',
    'ks_(24).jpg',
    'ks_(25).jpg',
    'ks_(26).jpg',
    'ks_(27).jpg',
    'ks_(28).jpg',
    'ks_(29).jpg',
    'ks_(30).jpg',
    'ks_(31).JPG',
    'ks_(32).JPG',
    'ks_(33).jpg',
    'ks_(34).jpg',
    'ks_(35).jpg',
    'ks_(36).jpg',
    'ks_(37).jpg',
    'ks_(38).jpg',
    'ks_(39).jpg',
    'ks_(40).jpg',
    'ks_(41).jpg',
    'ks_(42).jpg',
    'ks_(43).jpg',
    'ks_(44).jpg',
    'ks_(45).jpg',
    'ks_(46).jpg',
    'ks_(47).jpg',
    'ks_(48).JPG',
    'ks_(49).JPG',
    'ks_(50).JPG',
    'ks_(51).JPG',
    'ks_(52).JPG',
    'ks_(53).JPG',
    'ks_(54).png'
  ];


export const galleryVideos = [
  { id: 1, title: 'Video 1', url: 'assets/video/kranti-video/video1.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video1-thumb.png' },
  { id: 2, title: 'Video 2', url: 'assets/video/kranti-video/video2.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video2-thumb.png' },
  { id: 3, title: 'Video 3', url: 'assets/video/kranti-video/video3.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video3-thumb.png' },
  { id: 4, title: 'Video 4', url: 'assets/video/kranti-video/video4.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video4-thumb.png' },
  { id: 5, title: 'Video 5', url: 'assets/video/kranti-video/video5.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video5-thumb.png' },
  { id: 6, title: 'Video 6', url: 'assets/video/kranti-video/video6.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video6-thumb.png' },
  { id: 7, title: 'Video 7', url: 'assets/video/kranti-video/video7.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video7-thumb.png' },
  { id: 8, title: 'Video 8', url: 'assets/video/kranti-video/video8 .mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video8-thumb.png' },
  { id: 9, title: 'Video 9', url: 'assets/video/kranti-video/video9.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video9-thumb.png' },
  { id: 10, title: 'Video 10', url: 'assets/video/kranti-video/video10.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video10.png' },
  { id: 11, title: 'Video 11', url: 'assets/video/kranti-video/video11.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video11-thumb.png' },
  { id: 12, title: 'Video 12', url: 'assets/video/kranti-video/video12.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video12-thumb.png' },
  { id: 13, title: 'Video 13', url: 'assets/video/kranti-video/video13.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video13-thumb.png' },
  { id: 14, title: 'Video 14', url: 'assets/video/kranti-video/video14.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video14.png' },
  { id: 15, title: 'Video 15', url: 'assets/video/kranti-video/video15.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video15.png' },
  { id: 16, title: 'Video 16', url: 'assets/video/kranti-video/video16.mp4', thumbnail:'assets/imgs/gallery/kranti-video-thumb/video16.png' },
];