import React, { useEffect, useState } from "react";
import "./TenderSuccess.css";
import { useNavigate } from "react-router-dom";
import { getLocalStorageData } from "../../services/localStorageService";
import { useGetPagesAccessQuery } from "../../services/userAuthApi";
import { getAssetPath } from "../../services/helpers";
import { useTranslation } from "react-i18next";

const TenderSucess = () => {

    const { t } = useTranslation();

    const { heading, para , btn} = t("tenderSuccess")

  const navigate = useNavigate();
  if (!getLocalStorageData("token") && getLocalStorageData("roleId") !== 2) {
    navigate("/TenderLogin");
  }
  //start access page based on Role id
  const [hasPageAccess, setPageAccess] = useState(false);
  const token = getLocalStorageData("token");
  const { data: pageAccess, isSuccess: getAccessSuccess } =
    useGetPagesAccessQuery({ token });
  useEffect(() => {
    if (getAccessSuccess) {
      const pathname = window.location.pathname;
      const pageExists = pageAccess.some((page) => pathname.includes(page));
      setPageAccess(pageExists);
    }
  }, [pageAccess, getAccessSuccess]);
  //end access page based on Role id
  const handleButtonClick = () => {
    navigate("/Tenders");
    window.location.reload();
  };
  return (
    <>
      {hasPageAccess ? (
        <section className="tenderapply-bg">
          <div className="Tenderapply-table">
            <div className="row">
              <div className="col-md-5 mr-auto">
                <div className="Sucess-bg">
                  <img
                    className="sucess"
                    alt="sucess"
                    src={getAssetPath("assets/icons/Sucess-icon.png")}
                  ></img>
                  <h2 className="sucess-title font-bold">
                    {heading}
                  </h2>
                  <p className="sucess-sbtxt font-regular">
                   {para}
                  </p>
                  <button
                    className="btn btn-default btn-round tender-submit"
                    onClick={handleButtonClick}
                  >
                    {btn}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        "Sorry!! does not have permission to access this page"
      )}
    </>
  );
};

export default TenderSucess;
