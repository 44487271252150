import React from "react";
import "./ResetLink.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";

const SucessReset = () => {

  const { t } = useTranslation();

  const { heading, para, btn } = t("successReset")

  return (
    <>
      <section className="login-bg">
        <div className="slider-login fslogin-bg">
          <div className="login-content">
            <div className="container">
              <div className="container login-form-row col-md-6 mg-tp sucess-reset-pass">
                <img
                  className="sucess"
                  alt="sucess"
                  src={getAssetPath("assets/icons/Sucess-icon.png")}
                ></img>
                <h2 className="sucess-title font-bold">
                  {heading}
                </h2>
                <p className="sucess-sbtxt font-regular">
                 {para}
                </p>
                <a href="/TenderLogin">
                  <button className="btn btn-default btn-round tender-submit font-bold">
                   {btn}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SucessReset;
