import React from "react";
import "./Distillery.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation } from "react-i18next";

const Distillery = () => {

  const { t } = useTranslation();

  const { banner, newsRoom , info, sections } = t("Distillery");

  var DistilleryImgs = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

  }
  var distilleryslider = {
    autoplay: false,
    dots: false,
    infinite: false,
    speed: 500,
    arrows:false,
    slidesToShow: 5,
    gap: 10,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false,
              arrows: true
            }
        },
        {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true
            }
        },
    ]
}

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="distillery-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.heading}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-4 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-5">{newsRoom.heading}</h2>
                <p className="department-sbtxt ">
                  {newsRoom.para1}
                  <br />
                  <br />
                  {newsRoom.para2}
                  <br />
                  <br />
                  {newsRoom.para3}
                </p>
              </div>
              <div className="col-md-6">
                <div className="agriculture-multimg">
                <Slider {...DistilleryImgs}>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/distillery/Distillery1.png")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/distillery//Distillery2.png")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/distillery//Distillery3.png")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="computer"
                        src={getAssetPath("assets/imgs/department/distillery//Distillery4.png")}
                      ></img>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tender-table" style={{backgroundColor : "#f6f6f6"}}>
        <div className="container">
          <div className="">
            <div className="">
              <div className="row d-flex justify-content-center">
                <div className="col-md-6">
                  <div className="login-search">
                    <div className="farmer-name">
                      <div className="username investor-title">
                        <h2 className="font-bold mb-0" style={{fontWeight : '600'}}>{info.heading1}</h2>
                      </div>
                    </div>
                  </div>
                  <div className="bt-text font-regular mb-3">
                    <p>
                    {info.info1}
                      <br />
                      <br />
                      {info.info2}
                      <br />
                      <br />
                      {info.info3}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 img-flex">
                  <img
                  className="department-img"
                  alt="disitillery"
                  src={getAssetPath("assets/imgs/department/distillery//Distillery5.png")}
                  ></img>
                </div>
              </div>
              <div className="data-row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                      <div className="dis-bg gap-itm">
                      <div className="dis-card-flex">
                          <div className="font-bold">
                            <p className="dis-card-title">{sections.section1.title}</p>
                          </div>
                          <div className="dis-card-txt bt-text font-regular mb-3">
                            <p>{sections.section1.text1}</p>
                            <p>{sections.section1.text2}</p>
                            <p>{sections.section1.text3}</p>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dis-bg gap-itm">
                    <div className="dis-card-flex">
                        <div className="font-bold">
                          <p className="dis-card-title">{sections.section2.title}</p>
                        </div>
                        <div className="dis-card-txt bt-text font-regular mb-3">
                          <p>{sections.section2.text1}</p>
                          <p>{sections.section2.text2}</p>
                          <p>{sections.section2.text3}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dis-bg gap-itm">
                    <div className="dis-card-flex">
                        <div className="font-bold">
                          <p className="dis-card-title">{sections.section3.title}</p>
                        </div>
                        <div className="dis-card-txt bt-text font-regular mb-3">
                          <p>{sections.section3.text1}</p>
                          <p>{sections.section3.text2}</p>
                          <p>{sections.section3.text3}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <div className="dis-bg gap-itm">
                    <div className="dis-card-flex">
                        <div className="font-bold">
                          <p className="dis-card-title">{sections.section4.title}</p>
                        </div>
                        <div className="dis-card-txt bt-text font-regular mb-3">
                          <p>{sections.section4.text1}</p>
                          <p>{sections.section4.text2}</p>
                          <p>{sections.section4.text3}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="dis-bg gap-itm">
                      <div className="dis-card-flex">
                        <div className="font-bold">
                          <p className="dis-card-title">{sections.section5.title}</p>
                        </div>
                        <div className="dis-card-txt  bt-text font-regular mb-3">
                          <p>{sections.section5.text1}</p>
                          <p>{sections.section5.text2}</p>
                          <p>{sections.section5.text3}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                
              </div>
              {/* <section>
              <div className="bt-title font-semibold">
                <p>{sections.section2.title}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{sections.section2.text1}</p>
                <p>{sections.section2.text2}</p>
                <p>{sections.section2.text3}</p>
              </div>
              <div className="bt-title font-semibold">
                <p>{sections.section3.title}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{sections.section3.text1}</p>
                <p>{sections.section3.text2}</p>
                <p>{sections.section3.text3}</p>
              </div>
              <div className="bt-title font-semibold">
                <p>{sections.section4.title}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{sections.section4.text1}</p>
                <p>{sections.section4.text2}</p>
                <p>{sections.section4.text3}</p>
              </div>
              <div className="bt-title font-semibold">
                <p>{sections.section5.title}</p>
              </div>
              <div className="bt-text font-regular mb-3">
                <p>{sections.section5.text1}</p>
                <p>{sections.section5.text2}</p>
                <p>{sections.section5.text3}</p>
              </div>
              </section> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Distillery;
