export const imageNames = [
    { id: 1, title: 'image1', thumbnail:'assets/imgs/department/civil/Falling-Film-Evaporator-Top.jpeg' },
    { id: 2, title: 'image2', thumbnail:'assets/imgs/department/civil/Falling-Film-Evaporator-Set.jpeg' },
    { id: 3, title: 'image3', thumbnail:'assets/imgs/department/civil/Countinious-Pan.jpeg' },
    { id: 4, title: 'image4', thumbnail:'assets/imgs/department/civil/Hopper.jpeg' },
    { id: 5, title: 'image5', thumbnail:'assets/imgs/department/civil/Batch-Pan.jpeg' },
    { id: 6, title: 'image6', thumbnail:'assets/imgs/department/civil/Centrifugal-Machine.jpeg' },
    { id: 7, title: 'image7', thumbnail:'assets/imgs/department/civil/Juice-Heater.jpeg' },

    // 'Falling-Film-Evaporator-Top.jpeg',
    // 'Falling-Film-Evaporator-Set.jpeg',
    // 'Countinious-Pan.jpeg',
    // 'Hopper.jpeg',
    // 'Batch-Pan.jpeg',
    // 'Centrifugal-Machine.jpeg',
    // 'Juice-Heater.jpeg',
 ];