import React, { useState } from "react";
import "./Manufacturing.css";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";
import { imageNames } from "../../Departments/Manufacturing/staticImageArray";
import { useTranslation } from "react-i18next";

const Manufacturing = () => {
  const { t } = useTranslation();

  const { banner, newsRoom ,imageTitle } = t("Manufacturing", { returnObjects: true });

  // var ManufacturingImgs = {
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   speed: 500,
  //   fade: true,
  //   cssEase: "linear",
  //   dots: true,
  //   infinite: true,
  //   arrows: false,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,

  // }
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageTitle, setSelectedImageTitle] = useState(null);
  const openModal = (imageName) => {
    setSelectedImage(imageName.thumbnail);
    setSelectedImageTitle(imageTitle[imageName.title]);
  }

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="manufacturing-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-5 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-4">{newsRoom.heading}</h2>
                <p className="department-sbtxt ">
                 {newsRoom.para}
                </p>
                <br />
                <p className="department-sbtxt ">
                 {newsRoom.para1}
                </p>
                <br />
                <p className="department-sbtxt ">
                 {newsRoom.para2}
                </p>
                <br />
              </div>
              <div className="col-md-6 img-flex">
                {/* <div className="agriculture-multimg">
                  <Slider {...ManufacturingImgs}>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img1.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img2.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img3.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img4.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img5.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img6.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img7.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img8.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img9.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img10.jpeg")}
                        ></img>
                    </div>
                    <div className="img-item">
                        <img
                            className="department-img"
                            alt="enginnering"
                            src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img11.jpeg")}
                        ></img>
                    </div>
                  </Slider>
                </div> */}
                <img
                  className="department-img"
                  alt="administration"
                  src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img1.jpeg")}
                ></img>
              </div>
            </div>
            <div className="row mb-5 d-flex justify-content-center">
              <div className="col-md-6 img-flex-manu">
                <img
                    className="department-img"
                    alt="administration"
                    src={getAssetPath("assets/imgs/department/manufacturing/manufacturing-img2.jpeg")}
                  ></img>
              </div>
              <div className="col-md-6">
                <p className="department-sbtxt pd-lft ">
                 {newsRoom.para3}
                </p>
                <br />
                <p className="department-sbtxt pd-lft ">
                 {newsRoom.para4}
                </p>
                <br />
                <p className="department-sbtxt pd-lft ">
                 {newsRoom.para5}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ngfc-bg" style={{backgroundColor : "#f6f6f6"}}>
        <div className="container">
          <div className="newsroom">
            <h2 className="investor-title mb-4">{newsRoom.imgheading}</h2>
            <div className="row">
              {imageNames.map((imageName, index) => (

                <div className="col-md-4">
                    <div className="deve-facility">
                        <div className="cane-facility-img" data-bs-toggle="modal" data-bs-target="#Modalimg" key={index}  onClick={() => openModal(imageName)}>
                            <img className="img-fluid gallery-img" src={imageName.thumbnail} alt={`manufacturing ${index + 1}`} />
                            <p className="department-sbtxt pd-lft  mt-2">{imageTitle[imageName.title]}</p>
                        </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <div className="modal fade" id="Modalimg" tabIndex="-1" aria-labelledby="Modalimg" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                    <a href="/" className="close" data-bs-dismiss="modal">
                        <img className="img-fluid" alt="close-modal" src={getAssetPath("assets/icons/close-modal.png")}></img>
                    </a>
                    <div className="row">
                        <div className="col-md-8" style={{margin : "0 auto"}}>
                            <div className="leadership-modal-img civil-img">
                                <img className="img-fluid ks-gallery-img w-100" alt="manufacruting" src={selectedImage}></img>
                                <div className="image-name">
                                  <div className="container">
                                      <p className="department-sbtxt ">{selectedImageTitle}</p>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Manufacturing;

