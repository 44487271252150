import React, {useEffect, useState} from "react";
import "./FcCaneRegister.css";
import { getAssetPath } from "../../../services/helpers";
import { useTranslation ,Trans } from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {getLocalStorageData} from "../../../services/localStorageService";
import {getApiDiffHeaders} from "../../../services/api/api";


const FcRegisterDetail = () => {
    const { t } = useTranslation();
    const { bannerInfo, registerTable} = t("fcregisterdetail");
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const farmerCode = getLocalStorageData('farmerCode') ?? null;
    const location = useLocation();
    const { regId } = location.state || {};  // default to empty object if state is undefined

    useEffect(() => {
        getRegistrationDetails().catch((error) => console.error("Error in getRegistrationDetails:", error));
    }, []);

    const handleToRedirectLocation = (path) => {
        navigate(path);
    };

    const getRegistrationDetails = async () => {
        // Prepare the data to send in the API request
        const requestData = {
            reg_id: regId,
        };

        try {
            // Fetch the tonnage details from the API
            const detailCaneInfoResponse = await getApiDiffHeaders('getdetailcaneinfo/', requestData);
            console.log('detailCaneInfo Response:', detailCaneInfoResponse);

            // Check if the response contains total data
            if (detailCaneInfoResponse) {
                setData(detailCaneInfoResponse);
            }
        } catch (error) {
            console.error('Failed to fetch tonnage details:', error);
        }
    };
    return (
        <>
        <div className="Tenders-headbanner">
            <div className="Farmer-banner col-md-12">
                <div className="container">
                    <h1 className="headingtenders font-bold">{bannerInfo.heading}</h1>
                    <div className="breadcrum">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-container">
                                <li className="breadcrumb-item brdm-itm">
                                    <button
                                        onClick={() => handleToRedirectLocation('/home')}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'inherit', // Use inherited color from parent
                                            textDecoration: 'underline', // Makes it look like a link
                                            cursor: 'pointer',
                                        }}
                                        tabIndex={0} // Make it focusable for keyboard users
                                    >
                                        {bannerInfo.link}
                                    </button>
                                </li>
                                <li
                                    className="breadcrumb-item brdm-itm"
                                    aria-current="page">
                                    <button
                                        onClick={() => handleToRedirectLocation('/fc-dashboard')}
                                        style={{
                                            background: 'none',
                                            border: 'none',
                                            color: 'inherit', // Use inherited color from parent
                                            textDecoration: 'underline', // Makes it look like a link
                                            cursor: 'pointer',
                                        }}
                                        tabIndex={0} // Make it focusable for keyboard users
                                    >
                                        {bannerInfo.text}
                                    </button>
                                </li>
                                <li
                                    className="breadcrumb-item brdm-itm active"
                                    aria-current="page">
                                    {bannerInfo.text2}
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

            <section className="tender-table">
                <div className="tendermain-table">
                    <div className="col-md-12">
                        <div className="famrer-logout mb-3">
                            <button
                                onClick={() => {
                                    handleToRedirectLocation('/fc-cane-register')
                                }}
                                className="btn btn-default btn-round tender-Register btn-back"
                            >
                                <img
                                    className="back"
                                    alt="back"
                                    src={getAssetPath("assets/icons/back-arrow.png")}
                                ></img>
                                {registerTable.back}
                            </button>
                    </div>
                    <div className="login-search mb-30">
                        <div className="farmer-name">
                            <div className="username">
                                <h2 className="font-bold">
                                    {registerTable.register} 864611
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="table-container">
                        <h2>Cane Registration Details</h2>
                        <table className="vertical-table">

                            {data.map((item,index) => (
                                <tbody key={data.length-index}>
                                <tr key={item.reg_id}>
                                    <td><strong>Reg ID:</strong></td>
                                    <td>{item.reg_id}</td>
                                </tr>
                                <tr>
                                    <td><strong>Reg No:</strong></td>
                                    <td>{item.reg_no}</td>
                                </tr>
                                <tr>
                                    <td><strong>Lagan Type:</strong></td>
                                    <td>{item.lagan_type_name}</td>
                                </tr>
                                <tr>
                                    <td><strong>Kacchi Tarikh:</strong></td>
                                    <td>{item.kacchi_tarikh}</td>
                                </tr>
                                <tr>
                                    <td><strong>Mobile Number:</strong></td>
                                    <td>{item.mobile_number}</td>
                                </tr>
                                <tr>
                                    <td><strong>Sub Village Name:</strong></td>
                                    <td>{item.sub_village_name}</td>
                                </tr>
                                <tr>
                                    <td><strong>Hangam Name:</strong></td>
                                    <td>{item.hangam_name}</td>
                                </tr>
                                <tr>
                                    <td><strong>Water Supply Name:</strong></td>
                                    <td>{item.water_supp_name}</td>
                                </tr>
                                <tr>
                                    <td><strong>Water Supply Scheme Name:</strong></td>
                                    <td>{item.water_supply_scheme_name}</td>
                                </tr>
                                <tr>
                                    <td><strong>Total Area :</strong></td>
                                    <td>{item.total_area}</td>
                                </tr>
                                <tr>
                                    <td><strong>Gat Name :</strong></td>
                                    <td>{item.gat_name}</td>
                                </tr>
                                <tr>
                                    <td><strong>Survey No:</strong></td>
                                    <td>{item.survey_no}</td>
                                </tr>
                                <tr>
                                    <td><strong>Farmer Name:</strong></td>
                                    <td>{`${item.farmer_fname} ${item.farmer_mname} ${item.farmer_lname}`}</td>
                                </tr>
                                <tr>
                                    <td><strong>Plantation Date:</strong></td>
                                    <td>{item.plantation_date}</td>
                                </tr>
                                <tr>
                                    <td><strong>Route Name:</strong></td>
                                    <td>{item.route_name}</td>
                                </tr>
                                </tbody>
                            ))}

                        </table>
                </div>

        </div>
        </div>
</section>

{/* farm image modal */
}
    <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
    >
        <div className="modal-xl modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
                <div className="modal-body">
                    <a href="/" className="close" data-bs-dismiss="modal">
                        <img className="img-fluid" alt="close-modal"
                             src={getAssetPath("assets/icons/close-modal.png")}></img>
                    </a>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="leadership-modal-img">
                                <img className="img-fluid ks-gallery-img w-100" alt="gallery-img"
                                     src={getAssetPath("assets/imgs/fc-dashboard/cane-req.png")}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
)

}

export default FcRegisterDetail
