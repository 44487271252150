import React, {useEffect, useState} from "react";
import "./FarmerInfo.css";
import {getAssetPath} from "../../services/helpers";
import {useTranslation, Trans} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {getLocalStorageData, removeKeyData, storeData} from "../../services/localStorageService";
import {getApi} from "../../services/api/api";

const FarmerInfo = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {bannerInfo, container, companyOverview} = t("farmerCorner");
    const {  farmerCorner } = t("fcdashboard");
    const storedUserData = JSON.parse(getLocalStorageData('userDetails')) ?? null;
    const [farmerData, setFarmerData] = useState(null);
    const [storedYearMaster, setStoredYearMaster] = useState(null);
    // const storedYearMaster = getLocalStorageData('seasonYears') ? JSON.parse(getLocalStorageData('seasonYears')) : null;


    useEffect(() => {


        setFarmerData(storedUserData);

        const fetchLocalStorageData = async() => {
            try {
                let test = getLocalStorageData('seasonYears');
                const yearMaster = test ? JSON.parse(getLocalStorageData('seasonYears')) : null;
                setStoredYearMaster(yearMaster);
            } catch (error) {
                console.error('Failed to fetch local storage data:', error);
            }
        };
        fetchLocalStorageData();
        if (!storedYearMaster || Object.keys(storedYearMaster).length === 0) {
            callSeasonApi().catch((error) => console.error("Error in callSeasonApi:", error));
        }
    }, []);


    const callSeasonApi = async () =>{
        try {
            // Fetch the tonnage details from the API
            const getYearMaster = await getApi('seasonyears/');
            if (getYearMaster) {
                let createSeasonYears = JSON.stringify(getYearMaster);
                await storeData('seasonYears', createSeasonYears); // No await if storeData is not async
            }
        } catch (error) {
            console.error('Failed to fetch tonnage details:', error);
        }
    }

    const handleLoginClick = (productId) => {
        navigate('/TenderLogin', {state: {fromPage: 'FarmerInfo'}});
    };

    const handleLogoutClick = () => {
        removeKeyData('userDetails', null );
        setFarmerData(null);
    };

    return (
        <>
            <div className="Tenders-headbanner">
                <div className="Farmer-banner col-md-12">
                    <div className="container">
                        <h1 className="headingtenders font-bold">{bannerInfo.heading}</h1>
                        <div className="breadcrum">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-container">
                                    <li className="breadcrumb-item brdm-itm">
                                        <a href="/home">{bannerInfo.link}</a>
                                    </li>
                                    <li
                                        className="breadcrumb-item brdm-itm active"
                                        aria-current="page">
                                        {bannerInfo.text}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <section className="simple-idea abt-pdtop">
                <div className="container">
                    <div className="row ">
                        <div className="container col-md-6">
                            <h2 className="idea-title">{container.heading}</h2>
                        </div>
                        <div className="container col-md-10">
                            <p className="idea-para">
                                {container.para}
                            </p>
                        </div>
                    </div>
                    {
                        farmerData == null ?
                            <div className="col-md-3 mx-auto mt-3" onClick={() => {
                                handleLoginClick()
                            }}>
                                <a>
                                    <button className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
                                        {companyOverview.btn}
                                        <img
                                            className="Arrow-1"
                                            alt="icon"
                                            src={getAssetPath("assets/icons/Arrow-1.png")}
                                        ></img>
                                    </button>
                                </a>
                            </div>
                            :
                            <div className="col-md-2 mx-auto mt-3" onClick={() => {
                                handleLogoutClick()
                            }}>
                                <a>
                                    <button className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
                                        {farmerCorner.logout}
                                        <img
                                            className="Arrow-1"
                                            alt="icon"
                                            src={getAssetPath("assets/icons/Arrow-1.png")}
                                        ></img>
                                    </button>
                                </a>
                            </div>
                    }

                </div>
                <img
                    className="simpleidea-img"
                    alt="sugar"
                    src={getAssetPath("assets/imgs/farmers-corner-img.png")}
                ></img>
            </section>

            {/* Kranti Shetkari Mitra App- */}
            <section className="ngfc-bg">
                <div className="container">
                    <div className="newsroom">
                        <div className="row mrg-b d-flex justify-content-center">
                            <div className="col-md-6 img-flex-menu">
                                <img className="department-img" alt="civil"
                                     src={getAssetPath("assets/imgs/new-home-facility3.png")}></img>
                            </div>
                            <div className="col-md-6">
                                <h2 className="investor-title mb-3"
                                    style={{fontSize: "32px;"}}>{container.heading1}</h2>
                                <p className="department-sbtxt ">
                                    {container.para1}
                                </p>
                                <br/>
                                <p className="department-sbtxt ">
                                    {container.para2}
                                </p>
                                <br/>
                            </div>
                        </div>
                        <div className="row mrg-b d-flex justify-content-center">
                            <div className="col-md-6">
                                <h2 className="investor-title mb-3"
                                    style={{fontSize: "32px;"}}>{container.heading2}</h2>
                                <p className="department-sbtxt ">
                                    {container.para3}
                                </p>
                                <br/>
                                <br/>
                            </div>
                            <div className="col-md-6 img-flex">
                                <img className="department-img" alt="civil"
                                     src={getAssetPath("assets/imgs/new-home-facility2.png")}></img>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <section className="company-overview">
        <div className="farmer-corner-wrp">
          <div className="container cmpn-details">
            <img
              className="rounded-img"
              alt="kranti-rounded-logo"
              src={getAssetPath("assets/imgs/rounded-logo.png")}
            ></img>
            <div className="col-md-6">
              <h2 className="farmerc-title font-bold">
                <Trans
                i18nKey={companyOverview.heading}
                components={{1: <span/> }}></Trans>
              </h2>
            </div>
            <div className="col-md-5">
              <p className="farmerc-subtxt font-regular">
               {companyOverview.para}
              </p>
            </div>
            <div className="col-md-2">
            <a href="TenderLogin">
              <button className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
               {companyOverview.btn}
                <img
                  className="Arrow-1"
                  alt="icon"
                  src={getAssetPath("assets/icons/Arrow-1.png")}
                ></img>
              </button>
             </a>
            </div>
          </div>
        </div>
      </section> */}

            {/* kranti mitra app download link section */}
            <div className="appdownload-bg">
                <div className="container">
                    <div className="kranti-app-bg">
                        <div className="app-content">
                            <div className="app-subheading">
                                <p>{companyOverview.subheading}</p>
                            </div>
                            <div className="app-heading">
                                <h2>{companyOverview.headingnew}</h2>
                            </div>
                            <div className="app-subtext">
                                <p>{companyOverview.subtext}</p>
                            </div>
                            <div className="col-md-3 mt-3 w-50 responisve-btn">
                                <a href="TenderLogin">
                                    <button className="btn btn-default btn-round learn-more-2 w-100 mb-3 font-bold">
                                        {companyOverview.applink}
                                        <img
                                            className="Arrow-1"
                                            alt="icon"
                                            src={getAssetPath("assets/icons/Arrow-1.png")}
                                        ></img>
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="app-mob-img">
                            <img
                                alt="app-mob-img"
                                src={getAssetPath("assets/imgs/kranti-mobile-app.png")}
                            ></img>
                        </div>
                    </div>
                </div>
            </div>

            {/* kranti-whatsapp bottom icon */}
            <a  href="https://api.whatsapp.com/send/?phone=919156945050&text&type=phone_number&app_absent=0" id="krantiwhatsapp" className="kranti-whatsapp-btm-icon">
            <div className="icn-msg">
                <img
                    alt="whatsapp-mobile"
                    src={getAssetPath("assets/icons/whatsapp-fc.png")}
                ></img>
                <p className="wa-msg">{companyOverview.wamsg}</p>
            </div>
            </a>

        </>
    );
};

export default FarmerInfo;
