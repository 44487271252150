import React from "react";
import "./Accounts.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAssetPath } from "../../../services/helpers";

import { useTranslation } from "react-i18next";

const Accounts = () => {
  const { t } = useTranslation();

  const { banner, newsRoom, tenderMain_Table } = t("Accounts");

  var AccountImgs = {
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    fade: true,
    cssEase: "linear",
    dots: true,
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,

  }

  return (
    <>
      <div className="Tenders-headbanner">
        <div className="accounts-banner col-md-12">
          <div className="container">
            <h1 className="headingtenders font-bold">{banner.title}</h1>
            <div className="breadcrum">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb bg-container">
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link1}</a>
                  </li>
                  <li className="breadcrumb-item brdm-itm">
                    <a href="/home">{banner.link2}</a>
                  </li>
                  <li
                    className="breadcrumb-item brdm-itm active"
                    aria-current="page"
                  >
                    {banner.text}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <section className="ngfc-bg">
        <div className="container">
          <div className="newsroom">
            <div className="row mb-5 d-flex justify-content-center">
              <div className="col-md-6">
                <h2 className="investor-title mb-4">{newsRoom.heading}</h2>
                <p className="department-sbtxt ">{newsRoom.para}</p>
                <br />
                <p className="department-sbtxt ">{newsRoom.para1}</p>
              </div>
              <div className="col-md-6">
                <div className="agriculture-multimg">
                <Slider {...AccountImgs}>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="accounts"
                        src={getAssetPath("assets/imgs/department/accounts/account1.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="accounts"
                        src={getAssetPath("assets/imgs/department/accounts/account2.jpg")}
                      ></img>
                    </div>
                    <div className="img-item">
                      <img
                        className="department-img"
                        alt="accounts"
                        src={getAssetPath("assets/imgs/department/accounts/account3.jpg")}
                      ></img>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tender-table">
        <div className="container">
          <div className="tendermain-table">
            <div className="col-md-12">
              <div className="login-search">
                <div className="farmer-name">
                  <div className="username investor-title">
                    <h2 className="font-bold mb-0" style={{fontWeight : '600'}} >{tenderMain_Table.heading}</h2>
                  </div>
                </div>
              </div>
              <div className="table-responsive mb-3">
                <table className="table table-striped table-hover ">
                  <thead className="thead-dark th-height">
                    <tr>
                      <th scope="col">{tenderMain_Table.tableHead.text1}</th>
                      <th scope="col">{tenderMain_Table.tableHead.text2}</th>
                      <th scope="col">{tenderMain_Table.tableHead.text3}</th>
                      <th scope="col">{tenderMain_Table.tableHead.text4}</th>
                      <th scope="col">{tenderMain_Table.tableHead.text5}</th>
                      <th scope="col">{tenderMain_Table.tableHead.text6}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row22.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row22.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row22.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row22.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row22.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row22.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row21.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row21.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row21.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row21.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row21.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row21.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row20.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row20.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row20.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row20.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row20.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row20.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row19.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row19.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row19.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row19.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row19.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row19.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row18.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row18.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row18.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row18.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row18.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row18.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row17.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row17.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row17.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row17.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row17.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row17.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row16.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row16.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row16.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row16.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row16.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row16.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row15.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row15.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row15.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row15.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row15.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row15.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row14.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row14.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row14.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row14.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row14.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row14.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row13.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row13.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row13.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row13.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row13.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row13.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row12.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row12.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row12.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row12.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row12.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row12.class}</td>
                    </tr>
                  
                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row11.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row11.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row11.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row11.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row11.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row11.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row10.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row10.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row10.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row10.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row10.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row10.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row9.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row9.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row9.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row9.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row9.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row9.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row8.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row8.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row8.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row8.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row8.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row8.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row7.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row7.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row7.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row7.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row7.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row7.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row6.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row6.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row6.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row6.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row6.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row6.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row5.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row5.season}</td>
                      <td className="red">
                        (-) {tenderMain_Table.tableBody.row5.currentPL}
                      </td>
                      <td className="red">
                        (-) {tenderMain_Table.tableBody.row5.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row5.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row5.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row4.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row4.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row4.currentPL}
                      </td>
                      <td className="red">
                        (-) {tenderMain_Table.tableBody.row4.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row4.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row4.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row3.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row3.season}</td>
                      <td className="red">
                        (-) {tenderMain_Table.tableBody.row3.currentPL}
                      </td>
                      <td className="red">
                        (-) {tenderMain_Table.tableBody.row3.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row3.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row3.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row2.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row2.season}</td>
                      <td className="red">
                        (-) {tenderMain_Table.tableBody.row2.currentPL}
                      </td>
                      <td className="red">
                        (-) {tenderMain_Table.tableBody.row2.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row2.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row2.class}</td>
                    </tr>

                    <tr className="tr-height">
                      <td>{tenderMain_Table.tableBody.row1.srNo}</td>
                      <td>{tenderMain_Table.tableBody.row1.season}</td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row1.currentPL}
                      </td>
                      <td className="green">
                        (+) {tenderMain_Table.tableBody.row1.accumulatedPL}
                      </td>
                      <td>{tenderMain_Table.tableBody.row1.newWorth}</td>
                      <td>{tenderMain_Table.tableBody.row1.class}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Accounts;
